<div id="wrapper">
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="15px" class="top-heading text-bolder text-color">
    
    <div fxFlex>{{ 'Add Attendance' | translate }}</div>
    <div fxFlex fxLayoutAlign="end center"> 
        <!-- <button mat-button class="editBtn" (click)="addAttendanceRow()">
            Add more
        </button> -->
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">

    <div fxFlex="18">
        <mat-form-field>
            <mat-label>{{ 'Select Staff' | translate}}</mat-label>
            <input type="text"
            aria-label="Number"
            matInput
            required
            [formControl]="Form.get('staffLabel')"
            [matAutocomplete]="auto3">
            <mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete" (optionSelected)="setStaffId()">
            <mat-option *ngFor="let option of filteredStaffs" [value]="option.name">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
            <!-- <mat-error *ngIf="getField('firstLanguageId', item)">{{getErrorMessage('firstLanguage')| translate}}</mat-error> -->
        </mat-form-field>
   </div>

   <div fxFlex="18">
    <mat-form-field (click)="desired2.open()">
        <mat-label>{{ 'Select date' | translate}}</mat-label>
        <input matInput
               [max]="currentDate"
               required
               (dateChange)="onDateChange()"
               [matDatepicker]="desired2"
               [(ngModel)]="dateValue"
               >
        <!-- <mat-error *ngIf="getField('matDateOfBirth')">{{getErrorMessage('matDateOfBirth')| translate}}</mat-error> -->
        <mat-icon matSuffix>
          <img [src]="calendar"
               style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
        </mat-icon>
        <mat-datepicker #desired2></mat-datepicker>
      </mat-form-field>
</div>
    
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="start center" class="top-heading text-bold">
    {{subTitle | translate}}
  </div>

<div fxLayout="row wrap"
     fxLayoutAlign="center center"
     fxLayoutGap="15px"
     style="background: #ECF2F2 !important;text-align: center;">

    <div fxFlex="18">
        <p class="text-normal-bold heading">
        {{ 'Branch Name' | translate }}
        </p>
    </div>

    <div fxFlex="13">
        <p class="text-normal-bold heading">
        {{ 'Sign In' | translate }}
        </p>
    </div>

    <div fxFlex="13">
        <p class="text-normal-bold heading">
        {{ 'Break Type' | translate }}
        </p>
    </div>

    <div fxFlex="13">
        <p class="text-normal-bold heading">
        {{ 'Break Start' | translate }}
        </p>
    </div>

    <div fxFlex="13">
        <p class="text-normal-bold heading">
        {{ 'Break End' | translate }}
        </p>
    </div>

    <div fxFlex="12">
        <p class="text-normal-bold heading" style="margin-left: 4%;">
        {{ 'Sign Out' | translate }}
        </p>
    </div>

    <div fxFlex="5">
      <div></div>
    </div>
</div>

<div>

</div>

<!-- *ngIf="Form.get('staffId').value && dateValue" -->
<form [formGroup]="Form">
    <mat-card style="padding-top: 0 !important;">

        <div formArrayName="attendanceLogs">

            <ng-container [formGroupName]="i" *ngFor="let log of attendancelogs.controls; let i=index">
                <div fxLayout="row wrap"
                fxLayoutAlign="center center"
                fxLayoutGap="15px"
                
                style="border-bottom: 1px solid lightgrey;height: 75px;"
                >
               
                   <div fxFlex="18">
                        <mat-form-field style="margin-bottom: 0;">
                            <mat-label>{{ 'Select Branch' | translate}}</mat-label>
                            <input type="text"
                            aria-label="Number"
                            matInput
                            required
                            [formControl]="log.get('branchLabel')"
                            [matAutocomplete]="auto3">
                            <mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete" (optionSelected)="setBranchId(log)">
                            <mat-option *ngFor="let option of log.get('filteredBranches').value" [value]="option.label">
                                {{option.label}}
                            </mat-option>
                            </mat-autocomplete>
                            <!-- <mat-error *ngIf="getField('firstLanguageId', item)">{{getErrorMessage('firstLanguage')| translate}}</mat-error> -->
                        </mat-form-field>
                   </div>
        
                   <div fxFlex="13">
                    <timepicker controlName="timeIn" (onSetTime)="onSetTimeSignIn($event, log, log.get('breakIn').value, i)" [disabled]="disableInput"
                    [time]="log.get('timeIn').value" [max]="log.get('timeOut').value" [showError]="log.get('timeIn').invalid"
                    [required]="true" label="Time In" [screen]="'session'"></timepicker>
                   </div>

                   <div fxFlex="13">
                    <mat-form-field style="margin-top: 5px;">
                        <mat-label>{{ 'Select Break Type' | translate}}</mat-label>
                           <mat-select formControlName="breakType" [disabled]="disableInput || !log.get('timeIn').value">
                               <mat-option value="offPremises"> {{ 'Off Premises' | translate}}</mat-option>
                               <mat-option value="onPremises"> {{ 'On Premises' | translate}}</mat-option>
                               <mat-option (click)="resetBreakType(log)" value="reset"> {{ 'Reset' | translate}}</mat-option>
                           </mat-select>
                       <!-- <mat-error *ngIf="getField('duration', staffProbationPeriodDetail)">{{getErrorMessage('duration')| translate}}</mat-error> -->
                        </mat-form-field>
                    </div>
        
                    <div fxFlex="13">
                        <timepicker controlName="breakIn" (onSetTime)="onSetTimeBreakIn($event, log, log.get('timeIn').value,0)" [disabled]="disableInput || !log.get('timeIn').value"
                                    [time]="log.get('breakIn').value" [max]="log.get('breakOut').value" [showError]="log.get('breakIn').invalid"
                                    label="Break In" [screen]="'session'"></timepicker>
                    </div>
                    <div fxFlex="13">
                        <timepicker controlName="breakOut" (onSetTime)="onSetTimeBreakOut($event, log, log.get('timeOut').value,0)" [disabled]="disableInput || !log.get('breakIn').value"
                                    [time]="log.get('breakOut').value" [min]="log.get('breakIn').value" [showError]="log.get('breakOut').invalid"
                                    label="Break Out" [screen]="'session'"></timepicker>
                    </div>
        
                    <div fxFlex="13">
                        <timepicker controlName="timeOut" (onSetTime)="onSetTimeSignOut($event, log, log.get('breakOut').value, i)" [disabled]="disableInput || !log.get('timeIn').value"
                        [time]="log.get('timeOut').value" [min]="log.get('timeIn').value" [showError]="log.get('timeOut').invalid"
                        label="Time Out" [screen]="'session'"></timepicker>
                    </div>

                    <div fxFlex="5">
                        <button mat-icon-button class="sideOption" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button (click)="addAnotherBreak(i)" [disabled]="!log.get('breakIn').value || !log.get('breakOut').value || !log.get('breakType').value" mat-menu-item>
                              <span>{{ 'Add another break' | translate }}</span>
                            </button>
                            <button *ngIf="i != 0" (click)="removeAttendance(i)" mat-menu-item>
                              <span>{{ 'Delete' | translate }}</span>
                            </button>
                          </mat-menu>
                    </div>
                </div>

                <div *ngIf="log.get('attendanceBreaksLogs').controls.length !=0" formArrayName="attendanceBreaksLogs">
                    <ng-container [formGroupName]="j" *ngFor="let breakData of log.get('attendanceBreaksLogs').controls; let j=index">
                        <div fxLayout="row wrap"
                        fxLayoutAlign="center center"
                        fxLayoutGap="15px"
                        *ngIf="j != 0"
                    
                        style="border-bottom: 1px solid lightgrey;height: 75px;"
                        >
                       
                            <div fxFlex="18">
                                    <p class="text-normal-bold" style="margin-top: 10px;">
                                    
                                    </p>
                            </div>
                
                            <div fxFlex="13">
                                    <p class="text-normal-bold" style="margin-top: 10px;">
                                        
                                    </p>
                            </div>
                            <div fxFlex="13">
                                <mat-form-field style="margin-top: 5px;">
                                    <mat-label>{{ 'Select Break Type' | translate }}</mat-label>
                                       <mat-select formControlName="breakType">
                                           <mat-option value="offPremises"> {{ 'Off Premises' | translate}}</mat-option>
                                           <mat-option value="onPremises"> {{ 'On Premises' | translate}}</mat-option>
                                           <mat-option (click)="resetBreakType(breakData)" value="reset"> {{ 'Reset' | translate}}</mat-option>
                                       </mat-select>
                                   <!-- <mat-error *ngIf="getField('duration', staffProbationPeriodDetail)">{{getErrorMessage('duration')| translate}}</mat-error> -->
                                    </mat-form-field>
                                </div>
                
                                <div fxFlex="13">
                                    <timepicker controlName="breakIn" (onSetTime)="onSetTimeBreak($event, breakData, j)" [disabled]="disableInput"
                                    [time]="breakData.get('breakIn').value" [max]="breakData.get('breakOut').value" [showError]="breakData.get('breakIn').invalid"
                                    [required]="true" label="Break In" [screen]="'session'"></timepicker>
                                </div>
                                <div fxFlex="13">
                                    <timepicker controlName="breakOut" (onSetTime)="onSetTimeBreakOut($event, breakData, log.get('timeOut').value, j)" [disabled]="disableInput || !breakData.get('breakIn').value"
                                    [time]="breakData.get('breakOut').value" [min]="breakData.get('breakIn').value" [max]="log.get('timeOut').value" [showError]="breakData.get('breakOut').invalid"
                                    [required]="true" label="Break Out" [screen]="'session'"></timepicker>
                                </div>
                
                                <div fxFlex="13">
         
                                </div>

                                <div fxFlex="5">
                                  <!-- <p class="text-normal-bold" style="margin-top: 10px;">
                                    Remove
                                  </p> -->
                                  <mat-icon style="margin-left: 14%; cursor: pointer" (click)="removeBreak(j,log)">close</mat-icon>
                                </div>
                            </div>
                    </ng-container>
                </div>              
            </ng-container>        
       </div>
    </mat-card>
  </form>

  <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="15px" style="margin-top: 10px;">
    <div fxFlex.sm="39" fxFlex.xs="100">
      <button (click)="onCancel()" type="button" mat-button style="margin-top: 10px;background: rgb(193, 187, 185) !important;" class="btn-custom-form-small">
        {{ 'Cancel' | translate }}
      </button>
    </div>
    <div fxFlex.sm="39" fxFlex.xs="100">
        <button [disabled]="isAddMoreDisable()" mat-button class="btn-custom-form" [ngStyle]="{'opacity': isAddMoreDisable() ? 0.4 : 1 }" style="background-color: #FCB54E ;margin-top: 10px;" (click)="addAttendanceRow()">
            {{ 'Add more' | translate }}
        </button>
    </div>
    <div fxFlex.sm="39" fxFlex.xs="100">
      <button (click)="onSubmit()" type="button" mat-button style="margin-top: 10px;" class="btn-custom-form-small">
        {{ 'Save' | translate }}
      </button>
    </div>
  </div>
  

</div>