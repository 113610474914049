<div *ngIf="chartType != 'circle'"
	 class="chart-wrapper">
	<div class="chart-inner">
		<div style="width:100%; height: 100%;"
			 class="chart"
			 [id]="chartContainer"></div>
	</div>
</div>

<ng-container *ngIf="chartType == 'circle'">
	<div class="progress-circle"
		 [ngClass]="chartType == 'circle' ? 'p' + circleChartClass : ''"
		 [class.over50]="circleChartClass > 49">
		<span>{{circleChartValue}}%</span>
		<div class="left-half-clipper">
			<div class="first50-bar"
				 [style.background]="circleChartColor"></div>
			<div class="value-bar"
				 [style.border]="border"></div>
		</div>
	</div>
	<div fxLayout="row wrap"
		 fxLayoutAlign="center center">
		<div style="width: 15px;height: 15px;border-radius: 25px;"
			 [style.background]="circleChartColor"></div>
		<div style="padding-left: 5px;font-size: 13px;">{{circleChartText}}</div>
	</div>
</ng-container>