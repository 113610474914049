<ng-container>
  <mat-card class="card">
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between center"
         fxLayoutGap="15px"
         class="top-heading text-bolder text-color">
      <div fxFlex>
        Booking Information
      </div>
    </div>
    <div class="top-heading text-bolder"
         style="font-size: 15px;">
      {{formDetail.firstName? formDetail.firstName : ''}} {{formDetail.lastName? formDetail.lastName : ''}}
    </div>
  </mat-card>
</ng-container>
<div>
  <app-table-component *ngIf="this.dataItems.length > 0"
                       maxHeight="40vh"
                       height="auto"
                       [props]="tableConfigAndProps"
                       [editableDate]="editableDateProps"
                       (selectedItem)="actionButtonOutput($event)"></app-table-component>

  <div fxLayout="row wrap"
       fxLayoutAlign="space-between center"
       class="row-card-margins"
       *ngIf="this.dataItems.length > 0">
    <div fxFlex="100"
         fxLayoutAlign="start start"
         class="row-wrap-margins">
      <div fxFlex="60"
           fxFlex.md="60"
           fxFlex.sm="60"
           fxFlex.xs="100"
           fxLayoutAlign="start start">
        <span style="background-color: #00D100; cursor:pointer;"
              class="slot-bar"></span>
        <span class="slot-bar-text">These bookings will be ended on the offboarding date</span>
      </div>
      <div fxFlex="40"
           fxFlex.md="40"
           fxFlex.sm="40"
           fxFlex.xs="100"
           fxLayoutAlign="start start">
        <span style="background-color: #F10000; cursor:pointer;"
              class="slot-bar"></span>
        <span class="slot-bar-text">These bookings will be deleted </span>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap"
       fxLayoutAlign="space-between center"
       class="row-card-margins"
       *ngIf="this.dataItems.length == 0">
    This child does not have any active bookings.
  </div>

  <app-form-foooter (backEvent)="goBack()"
                    (saveInfoEvent)="onNext()"
                    (cancelEvent)="onCancel()"
                    [footerProps]="footerProps"></app-form-foooter>
</div>

<!-- <app-form-foooter (backEvent)="goBack()"
[footerProps]="footerProps"></app-form-foooter> -->
