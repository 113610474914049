<ng-container *ngIf="!LongCard">
	<mat-card *ngIf="hasImage==='true' && !bigCard && !smallCard"
			  class="card">
		<div *ngIf="icon!==''"
			 fxLayout="row wrap"
			 fxLayoutAlign="start center"
			 style="margin-top: 10px;">
			<!-- <div [fxFlex]="imgHeight"> -->
				<div fxFlex
				 class="heading">
				<div style="color: #404040;padding-bottom: 10px;"
					 class='heading text-normal'>
					{{heading}}
				</div>
			</div>
			<div fxFlex
			style="text-align: right;">
				<img height="30"
					 class="image"
					 src="assets/images/sdn/{{icon}}">
			</div>
			<!-- <div fxFlex
				 style="text-align: right;"
				 [style.color]='colortype'
				 class="number">
				{{count}}
			</div> -->
		</div>

		<!-- <div fxLayout="row wrap"
			 fxLayoutAlign="space-between center"
			 fxLayoutGap="5px"
			 style="margin-top: 20px;">
			<div fxFlex
				 class="heading">
				<div style="color: #404040;padding-bottom: 10px;"
					 class='heading text-normal'>
					{{heading}}
				</div>
			</div>
		</div> -->
	</mat-card>

	<mat-card *ngIf="hasImage==='true' && smallCard"
			  class="card">
		<div *ngIf="icon!==''"
			 fxLayout="row wrap"
			 fxLayoutAlign="start center">
			<!-- <div [fxFlex]="imgHeight"> -->
			<div fxFlex
				 style="text-align: left; margin-left: 12px;margin-top: 10px;"
				 [style.color]='colortype'
				 class="number">
				{{count}}
			</div>

		</div>

		<div fxLayout="row wrap"
			 fxLayoutAlign="space-between center"
			 fxLayoutGap="5px"
			 style="margin-top: 10px;">
			<div fxFlex
				 class="text-normal">
				<div [style.color]='colortype'
					 class='heading text-normal'>
					{{heading}}
				</div>
			</div>
			<div fxFlex>
				<img height="30"
					 src="assets/images/qareeb/dashboard/{{icon}}"
					 style="margin-top: 5px;float: right; margin-right:8px ;">
			</div>
		</div>
	</mat-card>
</ng-container>


<mat-card *ngIf="LongCard"
		  class="card">
	<div fxLayout="row wrap"
		 fxLayoutAlign="space-between center"
		 fxLayoutGap="5px"
		 style="min-height: 40px;">
		<div fxFlex="10">
			<img height="30"
				 class="image"
				 src="assets/images/qareeb/dashboard/{{icon}}">
		</div>
		<div fxFlex
			 class="text-normal">
			<div style="color: #404040;"
				 class='heading'>
				{{heading}}
			</div>
		</div>
		<div [style.color]='colortype'
			 class="number">
			21232 CAR
		</div>
	</div>
</mat-card>

<div *ngIf="divLikeCard">
	<div fxLayout="row wrap"
		 fxLayoutAlign="space-between center"
		 fxLayoutGap="5px"
		 style="min-height: 40px;">
		<div fxFlex
			 class="heading">
			<div style=" color: rgb(128, 128, 128);"
				 class='heading'>
				{{heading}}
			</div>
		</div>
	</div>
	<div fxLayout="row wrap"
		 fxLayoutAlign="space-between center"
		 fxLayoutGap="5px"
		 style="min-height: 40px;margin-left: 8px;">
		<div fxFlex
			 [style.color]='colortype'
			 [class]="textClass">
			{{value}}
		</div>
	</div>
</div>
<mat-card *ngIf="infoCard"
		  style="background: #F5F7FF 0% 0% no-repeat padding-box; margin: 10px 20px;">
	<div fxLayout="row wrap"
		 style="margin:10px 2px; font: normal normal 600 18px/29px Poppins; color: #455FFF;">
		{{heading}}
	</div>
	<div fxLayout="row wrap"
		 style="margin:10px 5px ;">
		<div fxFlex
			 style="font: normal normal normal 14px/21px Poppins;">
			{{detailText}}
		</div>
	</div>
	<div fxLayout="row wrap"
		 style="margin:20px 2px;">
		<button class="header-button"
				mat-raised-button
				style="background: #798CFF;">In Progress</button>
	</div>
</mat-card>