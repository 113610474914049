<mat-card class="row-margin-tb card-dark">
    <div fxLayout="row wrap"
         fxLayoutAlign="start start"
         fxLayoutGap="10px">
        <div fxFlex="20"
             fxFlex.xs="100"
             fxFlex.sm="100"
             fxFlex.md="32"
             fxFill>
            <mat-card
               style="height: 100vh;"
               class="card height">
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     class="col-flex-margins for-select-row"
                     (click)="onFormClick(1)"
                     [class.form-menu-clicked]="formNo===1">
                    <span class="form-select-icon"> 1</span>
                    <span class="form-select-label">Select Child</span>
                </div>
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     class="col-flex-margins for-select-row"
                     (click)="onFormClick(2)"
                     [class.form-menu-clicked]="formNo===2">
                    <span class="form-select-icon"> 2</span>
                    <span class="form-select-label">Process Credits</span>
                </div>
            </mat-card>

        </div>
        <div fxFlex
            fxFlex.xs="100"
            fxFlex.sm="100"
            fxFlex.md="62">
            <!-- First Form -->
            <app-select-item *ngIf="formNo === 1"
                [pageHeading]="pageHeading"
                [filterHeading]="filterHeading"
                [columnHeader]="columnHeader"
                [footerProps]="footerProps"
                [filters]="filters"
                [headerProps]="headerProps"
                [inputData]="inputData"
                [hasHeaderCheckBox]="true"
                [selectedItems]="selectedItems"
                (emitSelectedItems)="onEmitSelectedItems($event)"
                (emitSelectionDone)="onEmitSelectionDone($event)"
                ></app-select-item>
            <!-- Second Form -->
            <app-credit-detail *ngIf="formNo === 2"
            [childIds]="selectedIds"></app-credit-detail>
        </div>
    </div>

</mat-card>