<mat-card class="row-margin-tb card-dark">
  <form [formGroup]="Form">
      <mat-card>
        <!-- New Code -->
        <div fxLayout="row wrap"
             fxLayoutAlign="space-between center"
             fxLayoutGap="10px">

        <div fxFlex class="top-heading text-bolder text-color">
          Invoice Properties
        </div>

        <div fxFlex>

          <button
        class="btn header-button view"
          (click)="openEditBooking()"
          mat-raised-button>View Booking</button>

          <button
          class="btn header-button edit"
          (click)="goToEdit()" *ngIf="type == 'view' && editPermit && formDetail?.invoice?.invoiceStatus == 'Draft'"
          mat-raised-button> <mat-icon class="editIcon">edit</mat-icon>Edit</button>

          <button
          *ngIf="isDraft"
          class="btn header-button approve"
          (click)="confirmInvoiceApprove()"
          mat-raised-button>Approve Invoice</button>

      </div>

        </div>
        <div class="infoSection">
          <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px">
            <div style="height: 250px;" class="card-info" fxFlex="50"> 
              <h3 class="card-head">Invoice Details</h3>
              <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px">
                <div fxFlex="32">
                  <p class="heading">Invoice ID</p>
                  <p> {{ formDetail?.invoiceId }} </p>
                </div>
                <div fxFlex="32">
                  <p class="heading">Invoice Date</p>
                  <p> {{ formDetail?.invoice?.invoiceDate | date : 'dd/MM/yyyy' }}  </p>
                </div>
                <div fxFlex="32">
                  <p class="heading">Due Date</p>
                  <p>{{ formDetail?.invoice?.dueDate | date : 'dd/MM/yyyy' }}</p>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                <div fxFlex="32">
                  <p class="heading">Status</p>
                  <p>{{ formDetail?.invoice?.invoiceStatus | titlecase}}</p>
                </div>
                <div fxFlex="60">
                  <p class="heading">Child Name</p>
                  <p>{{formDetail?.childnames}}</p>
                </div>
              </div>
            </div>
            <div style="height: 250px;" class="card-info" fxFlex="50">
              <h3 class="card-head">Payment Details</h3>
              <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px">
                <div fxFlex="40">
                  <p class="heading">Amount</p>
                  <p> {{ formDetail?.invoice ? '£' + (formDetail?.invoice?.amount | number : '1.2-2') : 'N/A' }}</p>
                </div>
                <div fxFlex="40">
                  <p class="heading">Total Discount Amount</p>
                  <p> {{ formDetail?.invoice ? '£' + (formDetail?.invoice?.discountedAmount | number : '1.2-2') : 'N/A'}}</p>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px">
                <div fxFlex="50">
                  <p class="heading">Net Amount</p>
                  <p> {{ formDetail?.invoice ? '£' + (formDetail?.invoice?.amountAfterDiscount | number : '1.2-2') : 'N/A' }}</p>
                </div>
              </div>
            </div>
          </div>
  
          <div class="card-info mt-20">
            <h3 class="card-head">Invoice To</h3>
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
               <div fxFlex="32">
                <p class="heading">Parent/Guardian</p>
                <p>
                  {{ formDetail?.guardianInfo?.type == 'guardian' ? formDetail?.guardianInfo?.name : formDetail?.guardianInfo?.organizationName }}
                </p>
               </div>
               <div fxFlex="62">
                <p class="heading">Address</p>
                <p>{{ formDetail?.guardianInfo?.address }}</p>
               </div>
            </div>
          </div>
        </div>

        <section #main>
          <ng-container *ngFor="let child of childInvoiceList">

            <div class="text-bolder mt-10 ml-10 mr-10">{{ child?.childInfo?.firstName }} {{ child?.childInfo?.lastName }}</div>
    
            <ng-container *ngFor="let invoice of child.childInvoiceTableList">
              <div style="font-size: 16px;" class="top-heading text-bolder mt-20">Fees for the month of {{invoice.invoiceMonth}} ({{invoice.bookingStartDate | date : 'dd/MM/yyyy'}} - {{ invoice.bookingEndDate == 'Recurring' ? invoice.bookingEndDate : (invoice.bookingEndDate | date : 'dd/MM/yyyy')}})</div>

              <table class="mt-10 table-list" >
                <thead>
                  <tr style="font-size: 15px;">
                    <th style="min-width: 5%;"></th>
                    <th *ngFor="let header of invoice.weekHeaders;let i = index">
                      <div style="margin-top: 5px;"> Week {{i + 1}} </div>
                      <div style="font-size: 12px;">{{ header.datesLabel }}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of invoice.weekDataRows">
                    <td class="left-header"> 
                      <strong> {{ row.day | titlecase }} </strong> 
                    </td>
                    <td *ngFor="let week of row.weekCells" class="session-list-cell">
                      <!-- <tr *ngFor="let session of day.week1.sessions">
                        <td>
                          {{ (session.startTime * 1000) | date : 'hh:mm a' }} - {{ (session.endTime * 1000) | date : 'hh:mm a' }} <br> (5hrs funded)
                          <br>
                          Add Ons: {{ getAddOnLabels(session.additionalItemsList) }}
                        </td>
                      </tr> -->
                      <div style="text-align: center;" *ngIf="!week.sessions || week.sessions.length == 0"> -- </div>
                      <ng-container *ngIf="week.sessions">
                        <div style="text-align: center;" *ngFor="let session of week.sessions">
                          <ng-template [ngIf]="session.isBankHoliday == 'yes' && session.isDiscountedHoliday == 'no'" [ngIfElse]="showSession">
                            <strong>
                              Charged Bank Holiday
                            </strong>
                          </ng-template>
                          
                          <ng-template #showSession>
  
                            <ng-container *ngIf="session.isTermHoliday && session.isTermHoliday == 'yes'">
                              Term Holiday
                            </ng-container>
  
                            <ng-container *ngIf="!session.isTermHoliday || session.isTermHoliday == 'no'">
                              <strong>
                                {{ session.startTime | date : 'hh:mm a' }} - {{ session.endTime | date : 'hh:mm a' }}
                                <ng-container *ngIf="session.fundedHours != 0">
                                  <br>
                                  ({{ session.fundedHours}} hrs funded)
                                </ng-container>
                                
                              </strong>
                              <br>
                              {{ session?.sessionPricingObject?.name }}
                              <br>
                              <ng-container *ngIf="session.additionalItemsList.length !== 0">
                                <!-- Add Ons: {{ getAddOnLabels(session.additionalItemsList) }} -->
                                <p style="margin-bottom: 0;" *ngFor="let item of session.additionalItemsList">
                                  {{ item.name }} - £{{ item.amount }}
                                </p>
                                <!-- <br> -->
                              </ng-container>                         
                              <!-- Amount: £{{ (session?.chargesOnCurrentSession || 0) | number:'.2-2' }} -->
                            </ng-container>
                            
                          </ng-template>
                          
                          <ng-container *ngIf="session.bankHolidayDescription">
                            {{ session.bankHolidayDescription }}
                          </ng-container>
                        </div>
                      </ng-container>
                      

                    </td>
             
                  </tr>
                  <tr class="stats">
                    <td class="left-header-stat">
                     <strong> Total Hrs </strong> 
                    </td>
                    <td *ngFor="let totalHours of invoice.totalHourCells">{{ totalHours }} hrs</td>
                    <!-- <td>{{ invoice?.totalHourObj?.week2 }} hrs</td>
                    <td>{{ invoice?.totalHourObj?.week3 }} hrs</td>
                    <td>{{ invoice?.totalHourObj?.week4 }} hrs</td>
                    <td>{{ invoice?.totalHourObj?.week5 }} hrs</td> -->
                  </tr>
                  <tr class="stats">
                    <td class="left-header-stat">
                     <strong> Funded Hrs </strong>  
                    </td>
                    <td *ngFor="let fundedHours of invoice.fundedHoursCells">{{ fundedHours }} hrs</td>
                    <!-- <td>{{ invoice?.fundedHoursObj?.week2 }} hrs</td>
                    <td>{{ invoice?.fundedHoursObj?.week3 }} hrs</td>
                    <td>{{ invoice?.fundedHoursObj?.week4 }} hrs</td>
                    <td>{{ invoice?.fundedHoursObj?.week5 }} hrs</td> -->
                  </tr>
                  <tr class="stats">
                    <td class="left-header-stat">
                      <strong> Amount </strong>
                    </td>
                    <td *ngFor="let amount of invoice.amountCells">£{{ amount }}</td>
                    <!-- <td>£{{ invoice?.amountObj?.week2 }}</td>
                    <td>£{{ invoice?.amountObj?.week3 }}</td>
                    <td>£{{ invoice?.amountObj?.week4 }}</td>
                    <td>£{{ invoice?.amountObj?.week5 }}</td> -->
                  </tr>
      
                  <!-- Add more rows as needed -->
                </tbody>
              </table>

              <div class="mt-10" *ngIf="invoice.isSplitted">
                <strong style="font-size: 14px;">
                  **This booking is partially financed. {{invoice.splitAmount}}% paid by this account**
                </strong>
              </div>
            </ng-container>
            
            <!-- <div fxLayout="row" fxLayoutAlign="end start">
              <button (click)="onAddItem()"
                      [disabled]="isAdditonalFieldsInvalid()"
                      type="button"
                      *ngIf="type != 'view'"
                      mat-button
                      style="margin-top: 10px;"
                      class="add-btn">
                Add
              </button>
            </div> -->
            

    


          </ng-container>

          <div class="mt-30">
            <div class="mb-30" fxLayout="row" fxLayoutAlign="space-between start">
              <div fxFlex class="top-heading text-bolder">
                Additional Items
              </div>
              <div fxFlex>
                <button *ngIf="type != 'view'"
                    class="btn header-button view"
                    (click)="openAdditionalItemDialog()"
                    mat-raised-button>Add New</button>
              </div>
            </div>
    
            <app-table-component (selectedItem)="actionButtonOutput($event)"
                                 [props]="tableConfigAndProps" 
                                 height="auto"></app-table-component>

            <div class="mt-10">
              <mat-card class="item-card mt-10">
                <!-- <div *ngFor="let item of additionalItems" fxLayout="row" fxLayoutAlign="end end" class="item-bar">
                  <div fxFlex="20">
                    <div class="text-small" style="color: #687784; margin: 15px 0px 15px -24px; display: flex;">
                      <p style="min-width: 100px;">Additional item: </p>
                      <p style="min-width: 140px" class="list-item"> {{ item.itemLabel }} </p>
                    </div>
                  </div>
                  <div fxFlex="20" fxLayoutAlign="center center">
                    <div class="text-small" style="color: #687784; margin: 15px 0px 15px -24px; display: flex;">
                      <p>Date: </p>
                      <p class="list-item"> {{ item.date | date:'dd/LL/yy' }} </p>
                    </div>
                  </div>
                  <div fxFlex="14">
                    <div class="text-small" style="color: #687784;margin: 15px 0px; display: flex;">
                      <p>Quantity: </p>
                      <p class="list-item"> {{ item.quantity }}</p>
                    </div>
                  </div>
                  <div fxFlex="20">
                    <div class="text-small" style="color: #687784;margin: 15px 0px; display: flex;">
                      <p>Price: </p>
                      <p class="list-item">&#163; {{ item.rate | number : '1.2-2' }}</p>
                    </div>
                  </div>
                  <div fxFlex="20" fxLayoutAlign="end end">
                    <div class="text-small" style="color: #687784; margin: 15px 0px 15px -24px; display: flex;">
                        <p class="subtotal-box">&#163; {{ item.rate * item.quantity | number : '1.2-2' }}</p>
                    </div>
                  </div>
                </div> -->
  
                <div  fxLayout="row" fxLayoutAlign="center center" class="item-bar">
                  <div fxFlex="80" fxLayoutAlign="start start" style="margin-left: 26px;">
                    <!-- <span class="text-small" style="color: #687784;">
                      Discount:
                    </span>
                    <span style="line-height: 18px;" class="list-item"> <strong> {{ formDetail?.invoiceDetails?.discount }}% </strong> </span>
  
                    <span class="text-small" style="color: #687784;margin-left: 40px;">
                      Discounted Amount:
                    </span>
                    <span style="line-height: 18px;" class="list-item">
                      <strong>&euro; {{ formDetail?.invoiceDetails?.discountedAmount }}</strong>
                    </span> -->
                  </div>
                  <div fxFlex="20" fxLayoutAlign="end end">
                    <div class="text-small" style="color: #687784; display: flex;margin-top: 8px;">
                      <p style="margin-top: 6px;">Sub Total :</p>
                      <p class="subtotal-box">&#163; {{ additionalItemSubTotal  | number : '1.2-2'}}</p>
                    </div>
                  </div>
                </div>
  
                <div fxLayout="row" fxLayoutAlign="center center" class="item-bar">
                  <div fxFlex="80" fxLayoutAlign="start start">
  
                    <!-- <span class="text-small" style="color: #687784;">
                      Amount:
                    </span>
                    <span style="line-height: 18px;" class="list-item"> <strong> &#163; {{ formDetail?.invoiceDetails?.amount | number : '1.2-2' }} </strong> </span> -->
  
                    <span class="text-small" style="color: #687784;margin-left: 40px;">
                      Total:
                    </span>
                    <span style="line-height: 18px;" class="list-item"> 
                      <strong> &#163; {{ (totalPayableMonthly + totalMonthlyDiscountedAmount + additionalItemSubTotal || 0) | number : '1.2-2' }} </strong> 
                    </span>
  
                    <span class="text-small" style="color: #687784;margin-left: 40px;">
                      Discounted Amount:
                    </span>
                    <span style="line-height: 18px;" class="list-item">
                      <strong>&#163; {{ (totalMonthlyDiscountedAmount || 0) | number : '1.2-2' }}</strong>
                    </span>
                  </div>
                  <div fxFlex="20" fxLayoutAlign="end end">
                    <div class="text-small" style="color: #687784; display: flex;margin-top: 8px;">
                      <p style="margin-top: 6px;">Net Amount :</p>
                      <p class="subtotal-box">&#163; {{ (totalPayableMonthly + additionalItemSubTotal || 0) | number : '1.2-2' }}</p>
                    </div>
                  </div>
                </div>
              </mat-card>   
            </div>                     
                
          </div>

          <div class="totals">
            <div [ngStyle]="{'border-bottom': surChargeAmount ? 'none' : '1px solid lightgray'}" class="totalSection mt-30" fxLayout="row" fxLayoutAlign="space-between start">
              <div fxFlex="50">
                <strong>Total free entitlement hours during period:</strong> {{ totalFundedHours || 0 }}
              </div>
              <div class="end" fxFlex="40">
               <strong>Total for period:</strong>  £{{ (totalPayable || 0) | number:'.2-2' }}
              </div>
            </div>
            <div *ngIf="surChargeAmount" class="totalSection" style="margin-bottom: 40px;" fxLayout="row" fxLayoutAlign="space-between start">
              <div fxFlex="50">
                <strong>Surcharge on Term Time Only bookings:</strong> {{ (surchargePercentage || 0) | number:'.2-2' }}%
              </div>
              <div class="end" fxFlex="40">
                <strong>Surcharge amount:</strong> £{{ (surChargeAmount || 0) | number:'.2-2' }}
              </div>
            </div>
          </div>
        </section>

        


         <!-- End New Code -->

        <!-- <div fxLayout="row wrap"
              fxLayoutAlign="space-between center"
              fxLayoutGap="10px"
              >
              <div fxFlex class="top-heading text-bolder text-color">
                Invoice Properties
              </div>

              <div fxFlex>

                <button
                class="header-button view"
                (click)="openEditBooking()"
                mat-raised-button>View Booking</button>

                <button
                class="header-button edit"
                (click)="goToEdit()" *ngIf="type == 'view' && editPermit && formDetail?.invoiceDetails?.invoiceStatus == 'Draft'"
                mat-raised-button> <mat-icon class="editIcon">edit</mat-icon>Edit</button>

                <button
                *ngIf="isDraft && type == 'view'"
                class="header-button approve"
                (click)="confirmInvoiceApprove()"
                mat-raised-button>Approve Invoice</button>

              </div>

          </div>
        <div fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="15px"
            class="row-card-margins">
          <div fxFlex.xl="20"
              fxFlex.lg="23"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Invoice ID
            <mat-form-field style="margin-top: 10px;">
              <input matInput
                    type="text"
                    formControlName="trackingCode"
                    />
          </mat-form-field>
          </div>
          <div fxFlex.xl="23"
              fxFlex.lg="23"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Invoice Date
            <mat-form-field (click)="desired1.open()"
                            style="margin-top: 10px;">
              <input matInput
                    readonly
                    (dateChange)="dateChange(Form,'date',$event)"
                    [matDatepicker]="desired1"
                    formControlName="createdDate"
                    required>
              <mat-icon matSuffix>
                <img [src]="calendar"
                    style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
              </mat-icon>
              <mat-datepicker #desired1></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxFlex.xl="20"
              fxFlex.lg="23"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Status
            <mat-form-field style="margin-top: 10px;">
              <input matInput
                    type="text"
                    formControlName="invoiceStatus"
                    required />
          </mat-form-field>
          </div>
          <div fxFlex.xl="23"
              fxFlex.lg="23"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Due Date
            <mat-form-field (click)="desired2.open()"
                            style="margin-top: 10px;">
              <input matInput
                    readonly
                    (dateChange)="dateChange(Form,'date',$event)"
                    [matDatepicker]="desired2"
                    formControlName="dueDate"
                    required>
              <mat-icon matSuffix>
                <img [src]="calendar"
                    style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
              </mat-icon>
              <mat-datepicker #desired2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
          class="top-heading text-small" style="font-size:15px;">
          Invoice To
        </div>

        <div fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="15px"
            class="row-card-margins">
          <div fxFlex.xl="23"
              fxFlex.lg="23"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Child
            <mat-form-field style="margin-top: 10px;">
  
              <input matInput
                    type="text"
                    formControlName="child"
                    required />
          </mat-form-field>
          </div>
          <div fxFlex.xl="23"
              fxFlex.lg="23"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Parent/Guardian
            <mat-form-field style="margin-top: 10px;">
        
              <input matInput
                    type="text"
                    formControlName="guardian"
                    required />
          </mat-form-field>
          </div>
          <div fxFlex.xl="23"
              fxFlex.lg="23"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
                Added Discount
            <mat-form-field style="margin-top: 10px;">
            <input matInput
                  type="text"
                  formControlName="discount"
                  required />
            <span style="font-size: 17px;" matSuffix>%&nbsp;</span>
            </mat-form-field>
          </div>
          <div fxFlex.xl="20"
              fxFlex.lg="47"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Address
            <mat-form-field style="margin-top: 10px;">
              <input matInput
                    type="text"
                    formControlName="address"
                    required />
          </mat-form-field>
          </div>
        </div>

        <div fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
          class="top-heading text-small" style="font-size:15px;">
          Add Additional Items
        </div>

        <div fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="15px"
            class="row-card-margins">
          <div fxFlex.xl="23"
              fxFlex.lg="23"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Select Item
            <mat-form-field style="margin-top: 10px;">
              <mat-select formControlName="item" placeholder="Additional Items" (selectionChange)="checkForAmount()">
                <mat-option *ngFor="let item of itemOptions" [value]="item">{{ item.label }}</mat-option>
              </mat-select>
          </mat-form-field>
          </div>
          <div fxFlex.xl="23"
              fxFlex.lg="23"
              fxFlex.md="25"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Select Date
            <mat-form-field (click)="desired3.open()"
                            style="margin-top: 10px;">
              <input matInput
                    readonly
                    (dateChange)="dateChangeStatic(Form,'selectDate',$event)"
                    [matDatepicker]="desired3"
                    formControlName="selectDate"
                    [min]="minDate"
                    [max]="maxDate"
                    >
              <mat-icon matSuffix>
                <img [src]="calendar"
                    style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
              </mat-icon>
              <mat-datepicker #desired3></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxFlex.xl="20"
              fxFlex.lg="12"
              fxFlex.md="12"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
            Amount
            <mat-form-field style="margin-top: 10px;">
              <input matInput
                    type="number"
                    min="1"
                    class="ml-15"
                    maxlength="5"
                    (blur)="onBlurEvent($event)"
                    formControlName="additionalAmount"
                      />
              <span class="pound-left" matSuffix>£&nbsp;</span>
            </mat-form-field>
          </div>
          <div fxFlex.xl="20"
              fxFlex.lg="12"
              fxFlex.md="12"
              fxFlex.sm="45"
              fxFlex.xs="100"
              class="text-small text-gray">
              Quantity
          <mat-form-field style="margin-top: 10px;">
            <input matInput
                    type="number"
                    min="0"
                    maxlength="4"
                    oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null; if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    formControlName="quantity"
                    />
        </mat-form-field>
    </div>

          <div fxFlex="75px">
          <button (click)="onAddItem()"
                  [disabled]="isAdditonalFieldsInvalid()"
                  type="button"
                  *ngIf="type != 'view'"
                  mat-button
                  style="margin-top: 10px;"
                  class="add-btn">
            Add
          </button>
        </div>
        <div fxFlex="120px">
          <button (click)="clearAdditionalItem()"
                  *ngIf="type != 'view'"
                  type="button"
                  mat-button
                  style="margin-top: 10px;"
                  class="cancel-btn">
            Cancel
          </button>
        </div>
        </div> -->
      </mat-card>
  </form>


    <!-- <mat-tab-group (selectedTabChange)="setTabIndex($event)">
      <mat-tab *ngFor="let booking of bookings;let i = index" [label]="(booking?.joiningDate | date:'dd/LL/yy') + ' to ' + (booking?.leavingDate == null ? 'Recurring' : (booking?.leavingDate | date:'dd/LL/yy')) ">
        <app-table-component (selectedItem)="actionButtonOutput($event)"
        [props]="getProps(booking)" height="auto"></app-table-component>
      </mat-tab>
    </mat-tab-group> -->


                      <!-- <div fxLayout="row" fxLayoutAlign="end end">
                        <div fxFlex="49" fxLayoutAlign="start start">
                            <div class="text-small" style="color: #687784;margin: 15px 0px; display: flex;">
                              <p>Total free entitlement hours during period : {{ formDetail?.invoiceDetails?.totalFreeEntitlementHours }}</p>
                            </div>
                          </div>
                        <div fxFlex="49" fxLayoutAlign="end end">
                          <div class="text-small" style="color: #687784;margin: 15px 20px; display: flex;">
                            <p style="margin-top: 6px;">Sub Total :</p>
                            <p class="subtotal-box">&#163;{{ bookings[selectedTabIndex]?.subTotal  }}</p>
                          </div>
                        </div>
                      </div> -->


  <mat-card style="border-radius: 0px !important;min-height: 40vh;">
    <app-form-foooter *ngIf="type != 'view'"
                      (clearEvent)="clearForm()"
                      (saveInfoEvent)="onSaveInfo()"
                      (backEvent)="goBack()"
                      [footerProps]="footerProps"></app-form-foooter>

  </mat-card>
</mat-card>
