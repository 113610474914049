import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adhoc-booking-detail',
  templateUrl: './adhoc-booking-detail.component.html',
  styleUrls: ['./adhoc-booking-detail.component.scss']
})
export class AdhocBookingDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
