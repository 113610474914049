<div class="mt-80">
    <mat-sidenav-container fxFlexFill>
        <mat-sidenav [mode]="mode"
                     [opened]="openSidenav"
                     fxFlex="275px"
                     #sidenav
                     >
            <app-side-panel [mode]="mode"
                            [sideNav]="sidenav"
                            [sideMenuOpened]="openSidenav"
                            (onManageMenu)="onWidthAdjustMent($event)"></app-side-panel>
        </mat-sidenav>
        <!-- <mat-sidenav [mode]="mode"
                     fxFlex.xl="255px"
                     [opened]="openSidenav"    fxFlex.md="275px" 
                     fxFlex.sm="275px"
                     fxFlex.xs="275px"
                     #rightNav
                     fxFlex="300px"
                     position="end">
            <app-custom-panel [mode]="mode"
                              [sideMenuOpened]="openSidenav"
                              (onManageMenu)="onManageMenu($event)"></app-custom-panel> [attr.fxFlex]="navOpened ? 'calc(100%-275px)': 'calc(100%-80px)'"
        </mat-sidenav> -->
        <mat-sidenav-content style="overflow: hidden;"
                             fxFlex>
            <div style="height: 60px;background-color: #ffffff;"
                 fxLayout="row wrap"
                 fxLayoutAlign="space-between center">
                <div fxFlex="40">
                    <span *ngIf="!sidenav.opened" (click)="onWidthAdjustMent($event)">
                        <mat-icon style="color: #FCB54E;
                        font-size: 30px;
                        margin-left: 30px;
                        position: relative;
                        top: 8px;
                        cursor: pointer;"
                        >menu</mat-icon>                    
                    </span>
                    <span (click)="onWidthAdjustMent($event)">
                        <img src="assets/images/sdn/branch.svg"
                             style="width: 35px; margin-left: 20px;">
                    </span>
                    <span style="color: #767676; font: normal normal 600 14px/37px Poppins;">
                        {{branchName}}
                    </span>
                    <!-- <button mat-icon-button
                            (click)="onManageMenu()">
                        <mat-icon style="color: #404040;"
                                  class="mat-18">subject</mat-icon>
                    </button> -->
                </div>
                <div fxFlex
                     class="main-logo-text"
                     style="color: #404040;font-weight: bold;">{{title}}</div>
                <div style=" margin-right: 20px;">
                    <!-- <button mat-icon-button
                            (click)="onManageRight()">
                        <mat-icon style="color: #404040;"
                                  class="mat-18">subject</mat-icon>
                    </button> -->
                    <select [(ngModel)]="languageSelected" (ngModelChange)="onLanguageChange()" style="border: 2px solid lightgray;
                    border-radius: 6px;
                    padding: 5px;
                    cursor: pointer;
                    margin-right: 25px;
                    font-size: 13px;" name="cars" id="cars">
                        <option value="english">English</option>
                        <option value="german">Dutch</option>
                      </select>
                    <span>
                        <img src="assets/images/sdn/user.svg"
                             style="width: 35px;">
                    </span>
                    <button style="font: normal normal 600 14px/37px Poppins; padding: 0px; margin-left:2px;"
                            mat-button
                            [matMenuTriggerFor]="menu">
                        {{user.name ? user.name : user.firstName}} {{user.lastName ? user.lastName : ''}}</button>
                    <mat-menu #menu="matMenu">
                        <button (click)="logOut()"
                                mat-menu-item>Sign Out</button>
                    </mat-menu>
                </div>
            </div>
            <div class="main-layout"
                 malihu-scrollbar
                 [scrollbarOptions]="scrollbarOptions">
                <router-outlet></router-outlet>

            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>