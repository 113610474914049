<div style="scale: 0.89;position: relative;bottom: 86px;right: 45px;" class="calendarcontainer container">
  <div class="row"
  *ngIf="view==='term'"
       fxLayout="row wrap"
       fxLayoutAlign="start start">
    <div fxFlex
         *ngFor="let Month of termCalendar;let m =index"
         class="col-md-3  col-sm-6 col-xs-12 ">
      <div class="monthcontainer ">
        <p class="monthname">{{Month.date | date:'MMMM'}}</p>
        <div class="flexdays">
          <div class="day-top"
               *ngFor="let label of days">
            {{label}}
          </div>
        </div>
     <!-- [style.background]="day?(day?.colors):''" -->
        <div *ngFor="let week of Month.days"
             class="flexdays"> 
          <div *ngFor="let day of week;let i=index"
               [ngClass]="day?.class"
               [style.background]="getBackground(day)"
               [style.border-top]="day?.colors? '1px solid'+day.colors:''"
               [style.border-bottom]="day?.colors? '1px solid'+day.colors:''"
               class="day"
               [popover]="yearcalendarpoptemplate"
               [outsideClick]="true"
               (click)="openDialog(day, Month)"
               placement="right"
               (onShown)="dayindex=i;getTodayEvents(day,m)">
            {{day?.day}}
            <!-- {{ day?.events?.length }} -->
          </div>
        </div>
      </div>
    </div>

  </div>
    <!--  [popover]="yearcalendarpoptemplate"
    [ngClass]="day?( day.istoday?'todayclass':(day.nb>0?'':'hasEvents'))  -->

  <div class="row"
  *ngIf="view =='year'"
       fxLayout="row wrap"
       fxLayoutAlign="space-between start">
    <div fxFlex
         *ngFor="let Month of calendar;let m =index"
         class="col-md-3  col-sm-6 col-xs-12 ">
      <div class="monthcontainer ">
        <p class="monthname">{{Month.date | date:'MMMM'}}</p>
        <div class="flexdays">
          <div class="day-top"
               *ngFor="let label of days">
            {{label}}
          </div>
        </div>
        <!--  [popover]="yearcalendarpoptemplate"
         [ngClass]="day?( day.istoday?'todayclass':(day.nb>0?'':'hasEvents')) -->
        <div *ngFor="let week of Month.days"
             class="flexdays"> 
          <div *ngFor="let day of week;let i=index"
               [ngClass]="day?.class"
               [style.background]="getBackground(day)"
               [style.border-top]="day?.events.length!=0? '1px solid'+ getBackground(day):''"
               [style.border-bottom]="day?.events.length!=0? '1px solid'+ getBackground(day):''"
               class="day"
               [popover]="yearcalendarpoptemplate"
               [outsideClick]="true"
               (click)="openDialog(day, Month)"
               placement="right"
               (onShown)="dayindex=i;getTodayEvents(day,m)">
            {{day?.day}}
            <!-- {{ day?.events?.length }} -->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="spinner"
     class="calendar-loading">
  <svg class="spinner"
       viewBox="25 25 50 50">
    <circle class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke-width="2"
            stroke-miterlimit="10" />
  </svg>
</div>

<!-- <ng-template #yearcalendarpoptemplate>
  <div class="col-md-12 col-calendar">
    {{openDialog(daydetails)}}
    <p [ngClass]="daydetails.istoday?'todaytext':''"
       class="pop_year_day">{{days[dayindex]}}.</p>
    <p [ngClass]="daydetails.istoday?'todaytext':''"
       class="pop_year_day_number">{{daydetails?.day}} </p>
    <div  *ngIf="!loader">
      <div *ngFor="let event of daydetails.events">
        <a [style.color]="event.color.primary+'!importants'"
           class="icon-action-calendar"
           *ngFor='let action of event.actions'
           [innerHTML]='action.label'
           (click)="actionClickedFn(action.name,event)">

        </a>
        <div class="circle_day_color"
             [style.background]="event.color.secondary"
             [style.border-color]="event.color.primary"></div>
        <p class="pop_year_event_title"
           (click)="eventClickedFn(event)">
          <span>
            {{event.startDate| date:'HH:mm'}}
          </span>
          {{event.description}}
        </p>

      </div>
    </div>
    <div class="progressbar_popyear"
         *ngIf="!daydetails.events||(daydetails.events.length==0)&&!loader">
      There are no events scheduled that day.
    </div>
    <div [hidden]="!loader"
         class="loader_popover_calendar"></div>
  </div>
</ng-template> -->