<mat-dialog-content style="max-height: 100vh;">
    <div style="width: 400px;">
        <div fxLayout="row wrap"
             fxLayoutAlign="center center"
             fxLayoutGap="5px">
            <div fxFlex="5">
            </div>
            <div fxFlex="90">
                <div fxLayout="row wrap"
                     fxLayoutAlign="center center"
                     fxLayoutGap="5px"
                     style="margin-right: 23px">
                    <div class="text-bold dark-blue-color row-margin">{{ 'CONFIRMATION' | translate }} </div>
                </div>

                <form [formGroup]="form">
                    <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     style="margin-top: 10px;">
                     <label style="margin-left: 5px;">{{ 'Please Select' | translate }}</label>
                    <div fxFlex="100">
                        <mat-radio-group 
                            aria-labelledby="Select an option"
                            (change)="radioChange($event)"
                            formControlName="option">
                            <mat-radio-button style="width: 45%" value="end">
                            {{ 'End Now' | translate }}
                            </mat-radio-button>
                            <mat-radio-button style="width: 45%" value="future">
                            {{ 'Select Date' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>                            
                    </div>
                </div>
                <div *ngIf="form.get('option').value == 'future'" fxLayout="row wrap"
                    fxLayoutAlign="center center"
                    style="margin-top: 30px;">
                   <div fxFlex="100">
                    <mat-form-field (click)="desired.open()">
                        <mat-label>{{ 'End Date' | translate}}</mat-label>
                        <input matInput
                               [min]="minStart"
                               formControlName="leavingDate"
                               readonly
                               (dateChange)="dateChangeStatic($event)"
                               [matDatepicker]="desired">
                        <mat-icon matSuffix>
                          <img [src]="calendar"
                               style="margin-bottom: 0px;
                                        margin-right: 10px; width: 16px; vertical-align: baseline;
                                         cursor: pointer;">
                        </mat-icon>
                        <mat-datepicker #desired></mat-datepicker>
                      </mat-form-field>
                   </div>
                </div>
                </form>


                <!-- <div *ngIf="alertData.type !== 'approval'"
                     fxLayout="row wrap"
                     fxLayoutAlign="center center">
                    <mat-card fxFlex="100">
                        <div fxLayout="row wrap"
                             fxLayoutAlign="space-between center"
                             fxLayoutGap="20px"
                             *ngFor="let item of alertData.dataToSubmit">
                            <div fxFlex>
                                <span class="text-small"
                                      style="margin-left: 10px;"> {{item.heading1}} </span>
                            </div>
                            <div fxFlex>
                                <mat-icon>edit</mat-icon>
                                <mat-icon>delete</mat-icon>
                            </div>
                        </div>
                    </mat-card>
                </div> -->
                
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="5px"
                     style="height:100px">
                    <div fxFlex>
                        <button mat-button
                                style="width: 90%;"
                                type="button"
                                class="btn-custom-form"
                                (click)="onCancelClick()">{{ 'Cancel' | translate }}</button>
                    </div>
                    <div fxFlex>
                        <button mat-button
                                style="width: 90%;"
                                class="btn-custom-form yellow"
                                type="button"
                                [disabled]="form.get('option').value == 'future' && !form.get('leavingDate').value"
                                (click)="endBooking()">{{ 'End Booking' | translate }}</button>
                    </div>
                </div>
            </div>
            <div fxFlex="5">
            </div>
        </div>
    </div>
</mat-dialog-content>

<!--    <div class="text-bold dark-blue-color">{{alertData.heading}} </div>
     
        </div> -->
