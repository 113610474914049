<form [formGroup]="Form"
      (ngSubmit)="onSubmit()">
  <ng-container>
    <mat-card class="card">
      <div fxLayout="row wrap"
           fxLayoutAlign="space-between center"
           fxLayoutGap="15px"
           class="top-heading text-bolder text-color">
        {{ 'Child’s Details' | translate }}
        <button mat-button class="editBtn" (click)="goToEdit()" *ngIf="type == 'view' && editPermit">
          <mat-icon class="editIcon">edit</mat-icon>
          {{ 'Edit' | translate }}
      </button>
      </div>
      <div fxLayout="row wrap"
           fxLayoutAlign="start stretch"
           fxLayoutGap="15px">
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100"
             fxFlexFill
             class="border-class col-flex-margins p-2"
             style=" border-radius: 14px;">
          <file-picker [label]="label"
                       [onlyImage]="onlyImage"
                       [image]="getImage('profilePicturePath')"
                       controlName="image"
                       (onFileSelect)="onFileSelect($event)"
                       (onDeleteFile)="onDeleteFile($event)">
          </file-picker>
        </div>
        <div fxFlex.xl="67"
             fxFlex.lg="67"
             fxFlex.md="67"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <div fxLayout="row wrap"
               fxLayoutAlign="start center"
               fxLayoutGap="15px">
            <div fxFlex.xl="39"
                 fxFlex.lg="44"
                 fxFlex.md="44"
                 fxFlex.sm="57"
                 fxFlex.xs="100"
                 class="col-flex-margins">
              <mat-form-field>
                <mat-label>{{ 'First Name' | translate }}</mat-label>
                <input matInput
                       type="text"
                       formControlName="firstName"
                       minlength="2"
                       required
                       maxlength="36" />
                <mat-error *ngIf="getField('firstName')">{{getErrorMessage('firstName')| translate}}</mat-error>
                <!-- <mat-error *ngIf="Form.get('firstName').hasError('whitespace')">no whitspace</mat-error> -->
              </mat-form-field>
            </div>
            <div fxFlex.xl="39"
                 fxFlex.lg="44"
                 fxFlex.md="44"
                 fxFlex.sm="57"
                 fxFlex.xs="100"
                 class="col-flex-margins">
              <mat-form-field>
                <mat-label>{{ 'Surname' | translate }}</mat-label>
                <input matInput
                       type="text"
                       required
                       formControlName="lastName"
                       minlength="2"
                       maxlength="36" />
                <mat-error *ngIf="getField('lastName')">{{getErrorMessage('lastName')| translate}}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex.xl="39"
                 fxFlex.lg="44"
                 fxFlex.md="44"
                 fxFlex.sm="57"
                 fxFlex.xs="100"
                 class="col-flex-margins">
              <mat-form-field>
                <mat-label>{{ 'Gender' | translate }}</mat-label>
                <mat-select required formControlName="gender">
                  <mat-option value="male">{{ 'Male' | translate }}</mat-option>
                  <mat-option value="female">{{ 'Female' | translate }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getField('gender')">{{getErrorMessage('gender')| translate}}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex.xl="39"
                 fxFlex.lg="44"
                 fxFlex.md="44"
                 fxFlex.sm="57"
                 fxFlex.xs="100"
                 class="col-flex-margins">
              <mat-form-field>
                <mat-label>{{ 'Known as' | translate }}</mat-label>
                <input matInput
                       type="text"
                       formControlName="knownAs"
                       minlength="2"
                       maxlength="36" />
                <mat-error *ngIf="getField('knownAs')">{{getErrorMessage('knownAs')| translate}}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex.xl="39"
                 fxFlex.lg="44"
                 fxFlex.md="44"
                 fxFlex.sm="57"
                 fxFlex.xs="100"
                 class="col-flex-margins">
              <mat-form-field (click)="desired2.open()">
                <mat-label>{{ 'Date of Birth' | translate }}</mat-label>
                <input matInput
                       (dateChange)="setDateFormat(Form, 'dateOfBirth', $event)"
                       [min]="minDate"
                       [max]="currentDate"
                        required
                       [matDatepicker]="desired2"
                       formControlName="matDateOfBirth">
                <mat-error *ngIf="getField('matDateOfBirth')">{{getErrorMessage('matDateOfBirth')| translate}}</mat-error>
                <mat-icon matSuffix>
                  <img [src]="calendar"
                       style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
                </mat-icon>
                <mat-datepicker #desired2></mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex.xl="39"
            fxFlex.lg="44"
            fxFlex.md="44"
            fxFlex.sm="57"
            fxFlex.xs="100"
            class="col-flex-margins">
         <mat-form-field (click)="desired3.open()">
           <mat-label>{{ 'Registration Date' | translate }}</mat-label>
           <input matInput
                  (dateChange)="setDateFormat(Form, 'registrationDate', $event)"
                  [min]="registrationMinDate"
                  [max]="currentDate"
                   required
                  [matDatepicker]="desired3"
                  formControlName="matRegistrationDate">
           <mat-error *ngIf="getField('matRegistrationDate')">{{getErrorMessage('matRegistrationDate')| translate}}</mat-error>
           <mat-icon matSuffix>
             <img [src]="calendar"
                  style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
           </mat-icon>
           <mat-datepicker #desired3></mat-datepicker>
         </mat-form-field>
       </div>


            
          </div>
        </div>
      </div>
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           class="row-wrap-margins">

                       <div ffxFlex.xl="25"
                       fxFlex.lg="30"
                       fxFlex.md="30"
                       fxFlex.sm="45"
                       fxFlex.xs="100"
                       class="col-flex-margins">

              <!-- <mat-form-field>
                <mat-label>Nationality</mat-label>
                <mat-select required formControlName="nationalityId">
                  <mat-option *ngFor="let item of nationalities" [value]="item.id"> {{ item.nationality }} </mat-option>
                </mat-select>
                <mat-error *ngIf="getField('nationalityId')">{{getErrorMessage('nationality')| translate}}</mat-error>
              </mat-form-field> -->

              <mat-form-field>
                <mat-label>{{ 'Nationality' | translate }}</mat-label>
                <input type="text"
                      aria-label="Number"
                      matInput
                      required
                      [formControl]="Form.get('nationalityLabel')"
                      [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="setNationalityId()">
                  <mat-option *ngFor="let option of filteredNationalities" [value]="option.nationality">
                    {{option.nationality}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="getField('nationalityId')">{{getErrorMessage('nationality')| translate}}</mat-error>
            </mat-form-field>

            </div>

        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100"
             class="col-flex-margins">

          <!-- <mat-form-field>
            <mat-label>Ethnic Origin</mat-label>
            <mat-select formControlName="ethnicOriginId">
              <mat-option *ngFor="let item of ethnicOrigins" [value]="item.id"> {{ item.origin }} </mat-option>
            </mat-select>
            <mat-error *ngIf="getField('ethnicOriginId')">{{getErrorMessage('ethnicOrigin')| translate}}</mat-error>
          </mat-form-field> -->

          <mat-form-field>
            <mat-label>{{ 'Ethnic Origin' | translate }}</mat-label>
            <input type="text"
                  aria-label="Number"
                  matInput
                  [formControl]="Form.get('ethinicOriginLabel')"
                  [matAutocomplete]="auto4">
            <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete" (optionSelected)="setEthinicOriginId()">
              <mat-option *ngFor="let option of filteredEthinicOrigins" [value]="option.origin">
                {{option.origin}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="getField('ethnicOriginId')">{{getErrorMessage('ethnicOrigin')| translate}}</mat-error>
        </mat-form-field>

        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100"
             class="col-flex-margins">

          <!-- <mat-form-field>
            <mat-label>First Language</mat-label>
            <mat-select formControlName="firstLanguageId">
              <mat-option *ngFor="let item of firstLanguages" [value]="item.id"> {{ item.language }} </mat-option>
            </mat-select>
            <mat-error *ngIf="getField('firstLanguageId')">{{getErrorMessage('firstLanguage')| translate}}</mat-error>
          </mat-form-field> -->

          <mat-form-field>
            <mat-label>{{ 'First Language' | translate }}</mat-label>
            <input type="text"
                  aria-label="Number"
                  matInput
                  [formControl]="Form.get('languageLabel')"
                  [matAutocomplete]="auto3">
            <mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete" (optionSelected)="setLanguageId()">
              <mat-option *ngFor="let option of filteredLanguages" [value]="option.language">
                {{option.language}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="getField('firstLanguageId')">{{getErrorMessage('firstLanguage')| translate}}</mat-error>
        </mat-form-field>

        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100"
             class="col-flex-margins">

          <!-- <mat-form-field>
            <mat-label>Religion</mat-label>
            <mat-select formControlName="religionId">
              <mat-option *ngFor="let item of religions" [value]="item.id"> {{ item.religion }} </mat-option>
            </mat-select>
            <mat-error *ngIf="getField('religionId')">{{getErrorMessage('religion')| translate}}</mat-error>
          </mat-form-field> -->

          <mat-form-field>
            <mat-label>{{ 'Religion' | translate }}</mat-label>
            <input type="text"
                  aria-label="Number"
                  matInput
                  [formControl]="Form.get('religionLabel')"
                  [matAutocomplete]="auto1">
            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" (optionSelected)="setReligionId()">
              <mat-option *ngFor="let option of filteredReligions" [value]="option.religion">
                {{option.religion}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="getField('religionId')">{{getErrorMessage('religion')| translate}}</mat-error>
        </mat-form-field>

        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100"
             class="col-flex-margins">
          <mat-form-field>
            <mat-label>{{ 'Religious Festivals' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="religiousFestivals" />
            <mat-error *ngIf="getField('religiousFestivals')">{{getErrorMessage('religiousFestivals')| translate}}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100"
             class="col-flex-margins">
          <mat-form-field>
            <mat-label>{{ 'Birth Certificate' | translate }}</mat-label>
            <input matInput
                   autocomplete="cc-name"
                   type="text"
                   formControlName="birthCertificateNumber"
                   minlength="4"
                   maxlength="18" />
            <mat-error *ngIf="getField('birthCertificateNumber')">{{getErrorMessage('birthCertificateNumber' | translate)}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100"
             class="col-flex-margins">
             <mat-form-field style="position: relative;">
              <mat-label>{{ 'Secret Code' | translate }}</mat-label>
                <input matInput placeholder="{{ 'Secret Code' | translate }}" [type]="hide ? 'password' : 'text'" formControlName="secretCode">
                <mat-icon style="cursor: pointer;position: absolute;right: 13px;top: 12px;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="getField('secretCode')">{{getErrorMessage('secretCode')}}
                </mat-error>
                <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
              </mat-form-field>
       </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100"
             class="col-flex-margins">
            <mat-form-field>
              <mat-label>{{ 'Passport No.' | translate }}</mat-label>
              <input matInput
                      type="text"
                      formControlName="passportNumber"
                      minlength="4"
                      maxlength="18" />
              <mat-error *ngIf="getField('passportNumber')">{{getErrorMessage('passportNumber')| translate}}
              </mat-error>
            </mat-form-field>
       </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
        </div>
      </div>
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           class="top-heading text-small text-gray">
        {{ 'Has the child attended an early year setting before?' | translate }}
      </div>
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px">
        <div fxFlex.xl="25"
             fxFlex.lg="40"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-radio-group aria-label="Select an option"
                           formControlName="hasChildAttendedNurseryBefore">
            <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div *ngIf="Form.controls['hasChildAttendedNurseryBefore'].value==true"
           fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           style="margin-top: 28px;"
           class="top-heading text-small text-gray">
        {{ 'Please enter below information' | translate }}
      </div>
      <ng-container [formGroup]="childPreviousNursery">
        <div *ngIf="Form.controls['hasChildAttendedNurseryBefore'].value==true"
             fxLayout="row wrap"
             fxLayoutAlign="start center"
             fxLayoutGap="15px"
             class="row-wrap-margins">
          <div fxFlex.xl="25"
               fxFlex.lg="30"
               fxFlex.md="30"
               fxFlex.sm="45"
               fxFlex.xs="100">
            <mat-form-field>
              <mat-label>{{ 'Nursery/ Pre-School Name' | translate }}</mat-label>
              <input matInput
                     type="text"
                     required
                     formControlName="nurseryName"
                     minlength="2"
                     maxlength="36" />
              <mat-error *ngIf="getField('nurseryName', childPreviousNursery)">{{getErrorMessage('nurseryName', childPreviousNursery)}}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.xl="25"
               fxFlex.lg="30"
               fxFlex.md="30"
               fxFlex.sm="45"
               fxFlex.xs="100">
            <mat-form-field (click)="desired.open()">
              <mat-label>{{ 'Session Start' | translate }}</mat-label>
              <input matInput
                     (dateChange)="setDateFormat(childPreviousNursery,'startDate',$event)"
                     [min]="minDate"
                     required
                     [max]="currentDate"
                     formControlName="matStartDate"
                     [matDatepicker]="desired">
              <mat-icon matSuffix>
                <img [src]="calendar"
                     style="margin-bottom: 0px;
                               margin-right: 10px; width: 16px; vertical-align: baseline;
                                cursor: pointer;">
              </mat-icon>
              <mat-datepicker #desired></mat-datepicker>
              <mat-error *ngIf="getField('matStartDate', childPreviousNursery)">{{getErrorMessage('matStartDate')}}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.xl="25"
               fxFlex.lg="30"
               fxFlex.md="30"
               fxFlex.sm="45"
               fxFlex.xs="100">
            <mat-form-field (click)="desired1.open()">
              <mat-label>{{ 'Session End' | translate }}</mat-label>
              <input matInput
                     required
                     (dateChange)="setDateFormat(childPreviousNursery,'endDate',$event)"
                     [min]="childPreviousNursery.get('startDate').value"
                     [disabled]="childPreviousNursery.get('startDate').value == null"
                     formControlName="matEndDate"
                     [matDatepicker]="desired1">
              <mat-icon matSuffix>
                <img [src]="calendar"
                     style="margin-bottom: 0px;
                               margin-right: 10px; width: 16px; vertical-align: baseline;
                                cursor: pointer;">
              </mat-icon>
              <mat-datepicker #desired1></mat-datepicker>
              <mat-error *ngIf="getField('matEndDate', childPreviousNursery)">{{getErrorMessage('matEndDate')| translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <!-- <div fxLayout="row"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           class="top-heading text-small text-gray">
        <div fxFlex="35">
          Has this child had a sibling at this Nursery?
        </div>
        <div fxFlex="35"
             *ngIf="Form.controls['childSiblingAtThisNursery'].value==true">
          When has child’s sibling been at this Nursery?
        </div>
        <div fxFlex="15"
             *ngIf="Form.controls['whenHasChildSiblingAtThisNursery'].value=='present'">

        </div>
      </div> -->
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px">
        <!-- <div fxFlex.xl="25"
             fxFlex.lg="35"
             fxFlex.md="35"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-radio-group aria-label="Select an option"
                           (change)="onIsSiblingAtNurseryChange($event)"
                           formControlName="childSiblingAtThisNursery">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div> -->

        <!-- <div fxFlex.xl="25"
             fxFlex.lg="35"
             fxFlex.md="35"
             fxFlex.sm="45"
             fxFlex.xs="100"
             *ngIf="Form.controls['childSiblingAtThisNursery'].value==true">
          <mat-radio-group aria-label="Select an option"
                           formControlName="whenHasChildSiblingAtThisNursery">
            <mat-radio-button value="present">Present</mat-radio-button>
            <mat-radio-button value="past">Past</mat-radio-button>
          </mat-radio-group>
        </div> -->

        <!-- <div fxFlex.xl="25"
             fxFlex.lg="20"
             fxFlex.md="20"
             fxFlex.sm="45"
             fxFlex.xs="100"
             >
        <mat-form-field class="discount" *ngIf="Form.controls['whenHasChildSiblingAtThisNursery'].value=='present' && showSiblingDiscount">
          <mat-label>Discount</mat-label>
          <input  matInput
                  (keypress)="isNegative($event)"
                  formControlName="matSiblingDiscount"
                  (blur)="onBlurEvent($event, 'siblingDiscount', 'matSiblingDiscount')"
                  step=".01"
                />
                <mat-error *ngIf="getField('matDiscount')">{{getErrorMessage('matDiscount')| translate}}</mat-error>
          <span matSuffix>%&nbsp;</span>
        </mat-form-field>
      </div> -->
      </div>

      <!-- <div *ngIf="Form.controls['whenHasChildSiblingAtThisNursery'].value=='present' && Form.controls['childSiblingAtThisNursery'].value" class="check">
        <mat-checkbox formControlName="isSiblingDiscountEnabled" (change)="onIsSiblingDiscountChange($event)"
                         class="example-margin">Apply Sibling Discount</mat-checkbox>
      </div> -->

      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           style="margin-top: 28px; margin-bottom: 28px;"
           class="top-heading text-bolder text-color">
        {{ 'Birth Certificate Details' | translate }}
      </div>
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           class="row-wrap-margins">
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-form-field>
            <mat-label>{{ 'Child’s Name' | translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="firstName"
                   [value]="Form.get('firstName').value + ' ' + Form.get('lastName').value"
                   readonly />
            <!-- <mat-error *ngIf="getField('id')">{{getErrorMessage('id')| translate}}</mat-error> -->
          </mat-form-field>
        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-form-field>
            <mat-label>{{ 'Birth Certificate Number' | translate }}</mat-label>
            <input matInput
                   type="text"
                   readonly
                   formControlName="birthCertificateNumber"
                   [value]="Form.get('birthCertificateNumber').value"
                   minlength="4"
                   maxlength="18" />
            <!-- <mat-error *ngIf="getField('id')">{{getErrorMessage('id')| translate}}</mat-error> -->
          </mat-form-field>
        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-form-field (click)="desired3.open()">
            <mat-label>{{ 'Date of Birth' | translate }}</mat-label>
            <input matInput
                   readonly
                   required
                   (dateChange)="setDateFormat(Form,'dateOfBirth',$event)"
                   [min]="minDate"
                   [max]="currentDate"
                   formControlName="matDateOfBirth"
                   [value]="Form.get('matDateOfBirth').value"
                   [matDatepicker]="desired3">
            <mat-icon matSuffix>
              <img [src]="calendar"
                   style="margin-bottom: 0px;
                                margin-right: 10px; width: 16px; vertical-align: baseline;
                                 cursor: pointer;">
            </mat-icon>
            <mat-datepicker #desired3></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-form-field class="test">
            <mat-label>{{ 'Sex At Birth' | translate }}</mat-label>
            <mat-select formControlName="gender"
                        [value]="Form.get('gender').value"
                        disabled>
              <mat-option disabled
                          value="male">{{ 'Male' | translate }}</mat-option>
              <mat-option disabled
                          value="female">{{ 'Female' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- <div fxLayout="row wrap"
           fxLayoutAlign="start"
           fxLayoutGap="15px">
        <div fxFlex="50"
             class="top-heading text-small text-gray">
          Staff Child
        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="48"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-radio-group aria-label="Select an option"
                           formControlName="isStaffChild" (change)="onIsStaffChildChange($event)">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex.xl="25"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100"
             *ngIf="Form.controls['isStaffChild'].value==true && showStaffDiscount">
          <mat-form-field class="discount">
            <mat-label>Discount</mat-label>
            <input matInput
                   (keypress)="isNegative($event)"
                   formControlName="matDiscount"
                   (blur)="onBlurEvent($event, 'discount', 'matDiscount')"
                   step=".01"
                  />
                  <mat-error *ngIf="getField('matDiscount')">{{getErrorMessage('matDiscount')| translate}}</mat-error>
            <span matSuffix>%&nbsp;</span>
          </mat-form-field>
        </div>
      </div> -->

      <div fxLayout="row wrap"
           fxLayoutAlign="start"
           fxLayoutGap="15px"
           >
          <div fxFlex="50"
                class="top-heading text-small text-gray">
            {{ 'Is this child eligible for Discount ?' | translate }}
          </div>
          <div fxFlex.xl="25"
                fxFlex.lg="48"
                fxFlex.md="30"
                fxFlex.sm="45"
                fxFlex.xs="100">
            <mat-radio-group aria-label="Select an option"
                             formControlName="isChildEligibleForDiscount">
              <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
              <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
            </mat-radio-group>
            <!-- <mat-checkbox formControlName="isStaffRelation" >Any Staff Relation</mat-checkbox> -->
          </div>
      </div>

      <div fxLayout="row wrap"
           fxLayoutAlign="start"
           fxLayoutGap="15px"
           *ngIf="Form.controls['isChildEligibleForDiscount'].value==true">
        <div fxFlex="50"
           class="top-heading text-small text-gray">
            {{ 'Select Discount Type' | translate }}
         </div>
        <div fxFlex.xl="25"
              fxFlex.lg="48"
              fxFlex.md="30"
              fxFlex.sm="45"
              fxFlex.xs="100">
                <mat-radio-group aria-label="Select an option"
                                (change)="setDiscountValue($event)"
                                formControlName="childDiscountType">
                  <!-- <mat-radio-button value="siblingDiscount">Sibling</mat-radio-button> -->
                  <mat-radio-button value="staffChildDiscount">{{ 'Staff Child' | translate }}</mat-radio-button>
                  <mat-radio-button style="width: 128px;" value="discretionaryDiscount">{{ 'Discretionary' | translate }}</mat-radio-button>
                  </mat-radio-group>
                  <mat-error style="font-size: 11px;margin-left: 14px;" *ngIf="getField('childDiscountType')">{{getErrorMessage('childDiscountType')| translate}}</mat-error>
          <!-- <mat-checkbox formControlName="isStaffRelation" >Any Staff Relation</mat-checkbox> -->
        </div>
     </div>

     <div  fxLayout="row wrap"
           fxLayoutAlign="start"
           fxLayoutGap="15px"
           class="mt-30">

           <div fxFlex.xl="25"
                fxFlex.lg="30"
                fxFlex.md="30"
                fxFlex.sm="45"
                fxFlex.xs="100"
                *ngIf="Form.controls['isChildEligibleForDiscount'].value && Form.controls['childDiscountType'].value">
                <mat-form-field class="discount">
                  <mat-label>{{ 'Discount' | translate }}</mat-label>
                  <input matInput
                        (keypress)="isNegative($event)"
                        formControlName="matDiscount"
                        (blur)="onBlurEvent($event, 'discount', 'matDiscount')"
                        step=".01"
                        />
                        <mat-error *ngIf="getField('matDiscount')">{{getErrorMessage('matDiscount')| translate}}</mat-error>
                  <span matSuffix>%&nbsp;</span>
                </mat-form-field>
           </div>
     </div>

      <!-- <div class="check">
        <mat-checkbox formControlName="isStaffDiscountEnabled" *ngIf="Form.controls['isStaffChild'].value==true" (change)="onIsStaffDiscountChange($event)"
                         class="example-margin">Apply Staff Discount</mat-checkbox>
      </div> -->
      <app-form-foooter *ngIf="type !== 'view'" (backEvent)="goBack()"
                        [footerProps]="footerProps"></app-form-foooter>
    </mat-card>
  </ng-container>
</form>
