<mat-card class="row-margin-tb card-dark">
    <div fxLayout="row wrap"
         fxLayoutAlign="start start"
         fxLayoutGap="10px">
        <div fxFlex="20"
             fxFlex.md="23"
             fxFlex.sm="100"
             fxFlex.xs="100"
             fxFill >
            <mat-card
               style="padding: 0 !important;"
               class="card height">
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     style="margin-left: 8px; margin-top: 20px;"
                     class="col-flex-margins for-select-row"
                     (click)="onFormClick(1)"
                     [ngClass]="{'form-menu-clicked': formNo === 1}">
                    <span class="form-select-icon"> 1</span>
                    <span class="form-select-label">Select Child</span>
                </div>
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     style="margin-left: 8px"
                     class="col-flex-margins for-select-row"
                     (click)="onFormClick(2)"
                     [ngClass]="{'form-menu-clicked': formNo === 2}">
                    <span class="form-select-icon"> 2</span>
                    <span class="form-select-label">Child Booking</span>
                </div>

                <mat-calendar class="calendarView" [(selected)]="selected"></mat-calendar>
            </mat-card>

        </div>
        <div fxFlex="78" fxFlex.md="75" fxFlex.sm="100" fxFlex.xs="100">
            <!-- First Form -->
            <app-select-child *ngIf="formNo === 1" (sendchildData)="receiveData($event)" [data]="childData" (back)="onBack()"></app-select-child>
            <app-booking-detail *ngIf="formNo === 2" [data]="childData" (back)="onBack()"></app-booking-detail>
            <!-- <app-adhoc-booking-detail *ngIf="formNo === 2 && bookingType == 'adhoc'"></app-adhoc-booking-detail> -->
            <!-- <app-multiple-booking-detail *ngIf="formNo === 2 && bookingType == 'multiple_session'"></app-multiple-booking-detail> -->
        </div>
    </div>

</mat-card>