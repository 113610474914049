<form [formGroup]="Form">
  <mat-card>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="15px"
         class="top-heading text-bolder text-color">
      {{ 'Additional Items Details' | translate }}
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="15px"
         class="row-card-margins">
      <div fxFlex.xl="20"
           fxFlex.lg="25"
           fxFlex.md="25"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="text-small text-gray">
        {{ 'Select Item' | translate }} <span style="color: red">*</span>
        <mat-form-field style="margin-top: 10px;">
          <!-- <mat-label>Gender</mat-label> -->
          <mat-select placeholder="{{ 'Select Any Item' | translate }}" (selectionChange)="onSelectItemFromDropdown($event.value)"
          required
                      formControlName="item">
            <mat-option *ngFor="let item of additionalItems" [value]="item.id"> {{item.name}}</mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="getField('gender')">{{getErrorMessage('gender')| translate}}</mat-error> -->
        </mat-form-field>
      </div>
      <div fxFlex.xl="23"
           fxFlex.lg="25"
           fxFlex.md="25"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="text-small text-gray">
        {{ 'Select Date' | translate }}
        <mat-form-field (click)="desired2.open()"
                        style="margin-top: 10px;">
          <input matInput
                 readonly
                 (dateChange)="dateChange(Form,'date',$event)"
                 [matDatepicker]="desired2"
                 formControlName="matDate"
                 required>
          <mat-icon matSuffix>
            <img [src]="calendar"
                 style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
          </mat-icon>
          <mat-datepicker #desired2></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex.xl="20"
           fxFlex.lg="25"
           fxFlex.md="25"
           fxFlex.sm="45"
           fxFlex.xs="100">
      </div>
      <div fxFlex.xl="20"
           fxFlex.lg="25"
           fxFlex.md="25"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="text-small text-gray">
        {{ 'Amount' | translate }} <span style="color: red">*</span>
        <mat-form-field style="margin-top: 10px;">
          <input matInput
                 class="ml-15"
                 formControlName="matRate"
                 (blur)="onBlurEvent($event)"
                 type="number"
                 maxlength = "999999"
                 required>
          <!-- <mat-error *ngIf="getField('gender')">{{getErrorMessage('gender')| translate}}</mat-error> -->
          <mat-error *ngIf="Form.get('matRate').hasError('max')"> {{ 'Maximum rate allowed upto 999999' | translate }}
          </mat-error>
          <span class="pound-left" matSuffix>£&nbsp;</span>
        </mat-form-field>
      </div>
      <div fxFlex.xl="20"
           fxFlex.lg="25"
           fxFlex.md="25"
           fxFlex.sm="45"
           fxFlex.xs="100">
        <button (click)="onAddItem()"
                type="button"
                mat-button
                style="margin-top: 10px;"
                class="btn-custom-form">
          {{ 'Add' | translate }}
        </button>
      </div>

    </div>
  </mat-card>
</form>
<!-- <ng-container height="20vh"> -->
  <app-table-component height="12vh" (selectedItem)="actionButtonOutput($event)"
  [props]="tableConfigAndProps"></app-table-component>
<!-- </ng-container> -->
                    <div fxLayout="row" fxLayoutAlign="end end">
                      <div fxFlex="99" fxLayoutAlign="end end">
                        <div class="text-small" style="color: #687784;margin: 15px 20px; display: flex;">
                          <p>Total :</p>
                          <p class="total-box">£{{getCount()}}</p>
                        </div>
                      </div>
                    </div>
<mat-card style="border-radius: 0px !important;min-height: 40vh;">
  <app-form-foooter (clearEvent)="clearForm()"
                    (saveInfoEvent)="onSaveInfo()"
                    (backEvent)="goBack()"
                    [footerProps]="footerProps"></app-form-foooter>

</mat-card>
