<div class="cal-month-view" role="grid">
    <mwl-calendar-month-view-header
      [days]="columnHeaders"
      [locale]="locale"
      (columnHeaderClicked)="columnHeaderClicked.emit($event)"
      [customTemplate]="headerTemplate"
    >
    </mwl-calendar-month-view-header>
    <div class="cal-days">
      <div
        *ngFor="let rowIndex of view.rowOffsets; trackBy: trackByRowOffset"
      >
        <div class="cal-cell-row">
          <mwl-calendar-month-cell
            *ngFor="
              let day of view.days
                | slice: rowIndex:rowIndex + view.totalDaysVisibleInWeek;
              trackBy: trackByDate
            "
            style="border:0.5px #E0E0E0 solid !important"
            [ngClass]="day?.cssClass"
            [day]="day"
            [openDay]="openDay"
            [locale]="locale"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipAppendToBody]="tooltipAppendToBody"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipDelay]="tooltipDelay"
            [customTemplate]="cellTemplate" 
            [ngStyle]="{ 'background': toggleDayHighlight(day),'border-color':toggleDayHighlight(day)}"
            (mwlClick)="dayClicked.emit({ day: day, sourceEvent: $event })"
            [clickListenerDisabled]="dayClicked.observers.length === 0"
            (mwlKeydownEnter)="
              dayClicked.emit({ day: day, sourceEvent: $event })
            "
            (highlightDay)="toggleDayHighlight(day,$event.event, true)"
            (unhighlightDay)="toggleDayHighlight($event.event, false)"
            mwlDroppable
            dragOverClass="cal-drag-over"
            (drop)="
              eventDropped(
                day,
                $event.dropData.event,
                $event.dropData.draggedFrom
              )
            "
            (eventClicked)="
              eventClicked.emit({
                event: $event.event,
                sourceEvent: $event.sourceEvent
              })
            "
            [attr.tabindex]="{} | calendarA11y: 'monthCellTabIndex'"
          >
          {{toggleDayHighlight(day)}}
          </mwl-calendar-month-cell>
        </div>
        <mwl-calendar-open-day-events
          [locale]="locale"
          [isOpen]="openRowIndex === rowIndex"
          [events]="openDay?.events"
          [date]="openDay?.date"
          [customTemplate]="openDayEventsTemplate"
          [eventTitleTemplate]="eventTitleTemplate"
          [eventActionsTemplate]="eventActionsTemplate"
          (eventClicked)="
            eventClicked.emit({
              event: $event.event,
              sourceEvent: $event.sourceEvent
            })
          "
          mwlDroppable
          dragOverClass="cal-drag-over"
          (drop)="
            eventDropped(
              openDay,
              $event.dropData.event,
              $event.dropData.draggedFrom
            )
          "
        >
        </mwl-calendar-open-day-events>
      </div>
    </div>
  </div>