<mat-card class="row-margin-tb card-dark">
    <mat-card>
      <app-app-headers [headerProps]="buttonHeaderProps"></app-app-headers>
    </mat-card>
  
    <mat-card class="subheader">
      <p class="text-normal subheaderLabel">
        {{headerProps.searchConfig.label}}
      </p>
      <filter-control [hasButton]="true"
                      [hasClearButton]="true"
                      [layoutAlign]="layoutAlign"
                      (filnalFilters)="filnalFilters($event)"
                      [hasSearch]="true"
                      [hasDatePicker]="true"
                      [searchConfig]="headerProps?.searchConfig"
                      [builtInFilters]="headerProps?.builtInFilters"
                      [filterArray]="headerProps?.filterArray"></filter-control>
    </mat-card>
  
    <app-table-component (onPagination)="onPagination($event)"
                         (selectedItem)="actionButtonOutput($event)"
                         (emitColumnSortAction)="sortColumn($event)"
                         [sortFields]="sortFields"
                         [props]="tableConfigAndProps"></app-table-component>
  
  </mat-card>
  