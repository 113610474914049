<mat-card style="height: 110px;margin-top: 20px !important;">
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <div>
      <img style="background: transparent;
                width: 55px; height:55px;" src="assets/images/sdn/{{icon}}">
    </div>
    <div fxFlex style="margin-left: 10px;">
      <div style="font-size: 14px;
                color: #111e6c;
                margin-top: 7px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;">{{heading}}</div>
      <div style="font-size: 32px;
                font-weight: bold;
                color: #707070;
                margin-top: 7px;
                display: inline-block;">{{count}}</div>
      <span style="font-family: Heebo;
                margin-left: 5px;
                font-size: 18px;
                font-weight: lighter;
                text-align: center;
                color: #969BBE;">{{counttype}}</span>
    </div>
  </div>
</mat-card>
