<div class="top-heading text-bolder ">
  Unbooked

  <app-table-component (selectedItem)="actionButtonOutput($event)"
                       [props]="tableConfigAndProps"
                       [isViewClick]="true"
                       height="auto"
                       maxHeight="25vh"></app-table-component>

</div>
