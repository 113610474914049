<mat-card class="row-margin-tb card-dark">
    <div fxLayout="row wrap"
         fxLayoutAlign="start start"
         fxLayoutGap="10px">
        <div fxFlex="24"
            fxFlex.xs="100"
            fxFlex.sm="100"
            fxFlex.md="32"
             fxFill>
            <mat-card 
               style="height: 100vh;"
               class="card height">
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     class="col-flex-margins for-select-row"
                     (click)="onFormClick(1)"
                     [ngClass]="{'form-menu-clicked': formNo === 1}">
                    <span class="form-select-icon"> 1</span>
                    <span class="form-select-label">Select Children</span>
                </div>
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     class="col-flex-margins for-select-row"
                     (click)="onFormClick(2)"
                     [ngClass]="{'form-menu-clicked': formNo === 2}">
                    <span class="form-select-icon"> 2</span>
                    <span class="form-select-label">Additional Item Details</span>
                </div>
            </mat-card>

        </div>
        <div fxFlex="73" 
        fxFlex.xs="100"
        fxFlex.sm="100"
        fxFlex.md="62">
            
           <app-select-children *ngIf="formNo === 1" (sendchildData)="receiveData($event)" [itemData]="childData" (back)="onBack()"></app-select-children>
           <app-additional-item-detail *ngIf="formNo === 2" [itemData]="childData" (back)="onBack()"></app-additional-item-detail>

        </div>
    </div>

</mat-card>
