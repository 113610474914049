import { ChangeDetectorRef, Component } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sdn-cms';
  loading$ = this.loader.loading$;
  constructor(public loader: LoadingService, private cdr: ChangeDetectorRef, private translate: TranslateService) {
    
    // this.translate.use('de');

  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
 }
}
