<mat-card class="row-margin-tb card-dark">
    <div fxLayout="row wrap"
         fxLayoutAlign="start start"
         fxLayoutGap="15px">
        <!-- <div> -->
        <!-- style="height: 100vh;" -->
        <mat-card class="height" fxFlex="25"
                  fxFlex.xs="100"
                  fxFlex.sm="100"
                  fxFlex.md="32"
                  flexFill>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 class="col-flex-margins for-select-row"
                 (click)="onFormClick(1)"
                 [ngClass]="{'form-menu-clicked': formNo === 1}">
                <span class="form-select-icon"> 1</span>
                <!-- <span *ngIf="tickCheck('id',true)  && formNo!==1">

                    <img src="assets/images/sdn/ic_tick_green.png" class="green-tick">
                </span> -->
                <span class="form-select-label">Offboarding Details</span>
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 class="col-flex-margins for-select-row"
                 (click)="onFormClick(2)"
                 [ngClass]="{'form-menu-clicked': formNo === 2}">
                <span class="form-select-icon"> 2</span>
                <!-- <span *ngIf="formDetail?.childDoctorDetail && formNo!==3">
                      <img src="assets/images/sdn/ic_tick_green.png" class="green-tick">
                </span> -->
                <span class="form-select-label">Funding Information</span>
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 class="col-flex-margins for-select-row"
                 (click)="onFormClick(3)"
                 [ngClass]="{'form-menu-clicked': formNo === 3}">
                <span class="form-select-icon"> 3</span>
                <!-- <span *ngIf="tickCheck('childGuardianDetails',false) && formNo!==2" >
                      <img src="assets/images/sdn/ic_tick_green.png" class="green-tick">
                </span> -->
                <span class="form-select-label">Booking Information</span>
            </div>

            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 class="col-flex-margins for-select-row"
                 (click)="onFormClick(4)"
                 [ngClass]="{'form-menu-clicked': formNo === 4}">
                 <span class="form-select-icon"> 4</span>
                <!-- <span *ngIf="tickCheck('childEmergencyDetails',false) && formNo!==4"
                      >
                      <img src="assets/images/sdn/ic_tick_green.png" class="green-tick">
                </span> -->
                <!-- <mat-icon ngIf="formNo===4">tick</mat-icon> -->
                <span class="form-select-label">Summary</span>
            </div>

        </mat-card>
        <!-- </div> -->
        <div fxFlex="73" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="62">
            <!-- <router-outlet></router-outlet> -->
            <app-offboarding-details *ngIf="formNo === 1" (sendchildId)="getId($event)" [parentId]="parentId" (emitFormData)="onEmitFormData($event)" (previousFormData)="getPreviousFormData($event)" [previousForm]="previousForm" [formDetail]="formDetail" (back)="onBack()"></app-offboarding-details>
            <app-offboard-funding-info *ngIf="formNo === 2" [childId]="fundingId" [parentId]="parentId" (emitFormData)="onEmitFormData($event)" (back)="onBack()" [previousForm]="previousForm" [formDetail]="formDetail"></app-offboard-funding-info>
            <app-booking-information *ngIf="formNo === 3 && previousForm" [childId]="guardianInfoId" [parentId]="parentId" (emitFormData)="onEmitFormData($event)" [previousForm]="previousForm" (back)="onBack()" [formDetail]="formDetail"></app-booking-information>
            <app-child-summary *ngIf="formNo === 4"  [parentId]="parentId" (emitFormData)="onEmitFormData($event)" (back)="onBack()" [previousForm]="previousForm" [formDetail]="formDetail"></app-child-summary>

        </div>
    </div>
</mat-card>
