<mat-card class="row-margin-tb card-dark">
    <mat-card >
        <app-app-headers [headerProps]="buttonHeaderProps"></app-app-headers>
      </mat-card>
      
<mat-card style="background-color: #ffffff26 !important; border: 0px !important;">
    <p class="text-normal" style="color: #4A4A4A; line-height: 8px; margin-bottom: -5px;">
     Enter Child Name
    </p> 
     <filter-control 
         [hasButton]="true"
         [hasClearButton]="true"
         [layoutAlign]="layoutAlign"
         (filnalFilters)="filnalFilters($event)"
         [hasSearch]="true"
         [hasOnlyDate]="true"
         [searchConfig]="headerProps?.searchConfig"
         [builtInFilters]="headerProps?.builtInFilters"
         [filterArray]="headerProps?.filterArray"></filter-control>
   </mat-card>

<app-table-component (onPagination)="onPagination($event)"
                     (selectedItem)="actionButtonOutput($event)"
                     (emitColumnSortAction)="sortColumn($event)"
                     [sortFields]="sortFields"
                     height="50vh"
                     [props]="tableConfigAndProps" [isViewClick]="true"></app-table-component>

    </mat-card>