<div *ngIf="imageFile && !docFile"
     fxLayout="row wrap"
     fxLayoutAlign="center center"
     style="margin: 3% 0%;">

     <div fxFlex.xs="15"
          fxFlex="120px"
          fxFlexOffset="10"
          style="margin-top: 10px;">

          <img [style.height.px]="size.height"
               [style.width.px]="size.width"
               style="border-radius: 50%;border: 1px solid black"
               *ngIf="onlyImage"
               [src]="image" />

          <ng-container *ngIf="!onlyImage">
               <div class="my-drop-zone border-class"
                    [style.height.px]="size.height"
                    [style.width.px]="size.width">

                    <div class="fileContainer"
                         (mouseover)="onHover('over')"
                         (mouseleave)="onHover('out')"
                         [style.height.px]="size.height"
                         [style.width.px]="size.width">

                         <ng-container *ngIf="!image">
                              <div *ngIf="!isIconHidden"
                                   class="cloud-icon">
                                   <mat-icon>
                                        <img [src]="camera"
                                             style="width: 25px; vertical-align: baseline;">
                                   </mat-icon>
                              </div>

                              <div class="des"
                                   *ngIf="isIconHidden">{{description}}</div>
                         </ng-container>

                         <input type="file"
                                id="avatar"
                                accept="image/png, image/jpeg, image/jpg"
                                (change)="onFileChange($event)"
                                #fileInput />

                         <ng-container *ngIf="!onlyImage">
                              <div *ngIf="image"
                                   class="selected-img">
                                   <img [style.height.px]="size.height"
                                        [style.width.px]="size.width"
                                        [src]="image">
                                   <div *ngIf="isDeleteShown"
                                        class="delete"
                                        (click)="onDeleteImage()">
                                        <mat-icon>delete</mat-icon>
                                   </div>
                              </div>
                         </ng-container>

                    </div>
               </div>
          </ng-container>
     </div>

     <div class="text-smaller"
          style="margin: 10px 0px;text-align: center;"
          fxFlex="100"
          fxFlex.sm="100"
          fxFlex.xs="100">
          {{label}}
     </div>
</div>

<div *ngIf="!imageFile && docFile"
     fxLayout="row wrap"
     fxLayoutAlign="space-between start"
     style="margin: 20px;">
     <div fxFlex="38">
          <mat-card>
               <div fxLayout="row "
                    class="text-smaller">Add Attachments</div>
               <div fxLayout="row "
                    style="margin: 15px;">
                    <button class="picker-button"
                            mat-button>
                         <mat-icon matPrefix>
                              filter
                         </mat-icon>
                         <span style="margin-left: 10px;">
                              upload files
                         </span>
                    </button>
               </div>
          </mat-card>
     </div>
</div>