<form [formGroup]="Form">
  <mat-card class="card">
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px" class="top-heading text-bolder text-color">
      {{ 'Select Period' | translate }}
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px" class="top-heading text-bold ">
      {{childName}}
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px" class="row-wrap-margins">

      <div fxFlex="28" class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Invoice Period' | translate }}</mat-label>
          <mat-select required
                      formControlName="invoicePeriod">
            <mat-option value="monthly">{{ 'Monthly' | translate }}</mat-option>
            <!-- <mat-option value="custom">Custom</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div *ngIf="Form.get('invoicePeriod').value == 'monthly'" fxFlex="28" class="col-flex-margins">
        <mat-form-field>
          <mat-label>Month</mat-label>
          <mat-select formControlName="month">
            <mat-option value="jan">January</mat-option>
            <mat-option value="custom">February</mat-option>
            <mat-option value="monthly">March</mat-option>
            <mat-option value="custom">April</mat-option>
            <mat-option value="monthly">May</mat-option>
            <mat-option value="custom">June</mat-option>
            <mat-option value="custom">July</mat-option>
            <mat-option value="custom">August</mat-option>
            <mat-option value="custom">September</mat-option>
            <mat-option value="custom">October</mat-option>
            <mat-option value="custom">November</mat-option>
            <mat-option value="custom">December</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <div *ngIf="Form.get('invoicePeriod').value == 'custom'" fxFlex="28" class="col-flex-margins">
        <mat-form-field (click)="desired.open()">
          <mat-label>{{ 'Date From' | translate }} <span style="color: red">*</span></mat-label>
          <input matInput (dateChange)="dateChangeStatic(Form, 'dateFrom', $event)"
             formControlName="dateFrom" [matDatepicker]="desired">
          <mat-icon matSuffix>
            <img [src]="calendar" style="margin-bottom: 0px;
                          margin-right: 10px; width: 16px; vertical-align: baseline;
                           cursor: pointer;">
          </mat-icon>
          <mat-datepicker #desired></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="Form.get('invoicePeriod').value == 'custom'" fxFlex="28" class="col-flex-margins">
        <mat-form-field (click)="desired1.open()">
          <mat-label>{{ 'Date To' | translate }} <span style="color: red">*</span></mat-label>
          <input matInput

                 (dateChange)="dateChangeStatic(Form, 'dateTo', $event)"
                 formControlName="dateTo"
                 [matDatepicker]="desired1"
                 [disabled]="!Form.get('dateFrom').value"
                 [min]="Form.get('dateFrom').value">
          <mat-icon matSuffix>
            <img [src]="calendar"
                 style="margin-bottom: 0px;
                          margin-right: 10px; width: 16px; vertical-align: baseline;
                           cursor: pointer;">
          </mat-icon>
          <mat-datepicker #desired1></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px" class="row-wrap-margins">
      <div fxFlex="28" class="col-flex-margins">
        <mat-form-field (click)="desired.open()">
          <mat-label>{{ 'Invoice Date' | translate }} <span style="color: red">*</span></mat-label>
          <input matInput (dateChange)="dateChangeStatic(Form, 'invoiceDate', $event)"
             formControlName="invoiceDate" [matDatepicker]="desired">
          <mat-icon matSuffix>
            <img [src]="calendar" style="margin-bottom: 0px;
                          margin-right: 10px; width: 16px; vertical-align: baseline;
                           cursor: pointer;">
          </mat-icon>
          <mat-datepicker #desired></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxFlex="28" class="col-flex-margins">
        <mat-form-field (click)="desired1.open()">
          <mat-label>{{ 'Due Date' | translate }} <span style="color: red">*</span></mat-label>
          <input matInput

                 (dateChange)="dateChangeStatic(Form, 'dueDate', $event)"
                 formControlName="dueDate"
                 [matDatepicker]="desired1"
                 [disabled]="!Form.get('invoiceDate').value"
                 [min]="Form.get('invoiceDate').value">
          <mat-icon matSuffix>
            <img [src]="calendar"
                 style="margin-bottom: 0px;
                          margin-right: 10px; width: 16px; vertical-align: baseline;
                           cursor: pointer;">
          </mat-icon>
          <mat-datepicker #desired1></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

      <!-- <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="row-wrap-margins">
      <div fxFlex="70"class="col-flex-margins">
        <mat-form-field>
          <mat-label>Memo</mat-label>
          <textarea style="resize: none;" matInput type="text" formControlName="memo" required></textarea>
          <mat-error *ngIf="getField('memo')">{{getErrorMessage('memo')| translate}}</mat-error>
        </mat-form-field>
      </div>
    </div> -->
    <!-- <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="row-wrap-margins">
      <div fxFlex.xl="23" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="45" fxFlex.xs="100">
        <mat-form-field>
          <mat-label>Amount</mat-label>
          <input matInput type="text" mask="9999" [specialCharacters]="[]" formControlName="amount" minlength="2"
            maxlength="4" min="1" required>
          <mat-error *ngIf="getField('amount')">{{getErrorMessage('amount')| translate}}</mat-error>
        </mat-form-field>
      </div>
    </div> -->

    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px" class="row-margin">
      <div fxFlex="150px">
        <button (click)="onDateSelected()" type="button" mat-button class="btn-custom-form">
          {{this.footerProps?.buttonLabel | translate}}
        </button>
      </div>
    </div>
    <!-- <app-form-foooter (saveInfoEvent)="onDateSelected()"
    (clearEvent)="clearForm()"
    [footerProps]="footerProps"></app-form-foooter> -->

  </mat-card>

</form>


