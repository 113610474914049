<form [formGroup]="Form">
    <ng-container>
      <div fxLayout="row wrap"
                   fxLayoutAlign="start center"
                   fxLayoutGap="15px"
                   class="top-heading text-bolder text-color row-margin">
                   Select Branch
              </div>
              <div fxLayout="row wrap"
					 fxLayoutAlign="start center"
					 fxLayoutGap="2px"
					 style="margin-top: 20px;margin-left: 10px;">
					<div fxFlex="30"
						 *ngFor="let checkbox of checkBoxes">
						<mat-checkbox [(ngModel)]="checkbox.checked"
									  [disabled]="checkBoxDisabled"
									  [ngModelOptions]="{standalone: true}"
									  (change)="onCheckBox(checkbox)"
									  class="example-margin">{{checkbox.day}}</mat-checkbox>
					</div>
					<!-- <div fxFlex>
						<mat-checkbox [(ngModel)]="allDays"
									  [disabled]="checkBoxDisabled"
									  [ngModelOptions]="{standalone: true}"
									  (change)="onAllDay()"
									  class="example-margin">All</mat-checkbox>
					</div> -->
				</div>
              
  
    </ng-container>
  </form>
