
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between center">
      <div fxFlex="30"></div>
      <div fxFlex="30"
           style="display: flex; cursor: pointer; justify-content: center;">
        <mat-icon style="margin: 17px 20px 0px 0px; color: #A3A3A3;"
                  mwlCalendarPreviousView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()">keyboard_arrow_left</mat-icon>

        <!-- <h3 style="color: #666666; margin-top: 10px;">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
         -->
        <h3 *ngIf="view =='month'"
            class="caltitle">{{ viewDate | date: 'MMMM'}} {{ viewDate | date: 'yyyy'}} </h3>
        <h3 *ngIf="view=='year'"
            class="caltitle">{{ viewDate | date: 'yyyy'}} - {{ (viewDate.getFullYear() + 1)}} </h3>
            <h3 *ngIf="view=='term'"
            class="caltitle"> {{ viewDate | date: 'yyyy'}} - {{ (viewDate.getFullYear() + 1)}} </h3>

        <mat-icon style="margin: 17px 0px 0px 20px; color: #A3A3A3;"
                  mwlCalendarNextView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()">keyboard_arrow_right</mat-icon>

      </div>
      <div fxFlex="30"
           fxLayoutAlign="end end"
           style="margin: 0px 30px;">
        <button [disableRipple]="true"
                mat-stroked-button
                class="calbutton"
                (click)="setView('month')"
                [class.active]="view ==='month'">
          Month
        </button>
        <button style="margin-left: 8px;"
                [disableRipple]="true"
                mat-stroked-button
                class="calbutton"
                (click)="setView('term')"
                [class.active]="view === 'term'">
          Term
        </button>
        <button style="margin-left: 8px;"
                [disableRipple]="true"
                mat-stroked-button
                class="calbutton"
                (click)="setView('year')"
                [class.active]="view === 'year'">
          Year
        </button>
      </div>
      <div fxFlex="100"
           fxLayoutAlign="center center"
           style="margin-top: 30px; background-color: #F5F5F5; line-height: 40px;">
          <div fxFlex="16"
               fxFlex.sm="25"
            fxLayoutAlign="center center"
            (click)="termType(term.name)" *ngFor="let term of displayTerms;">
            <span [ngClass]="{'term-spring' : term.name == 'Spring Term', 'term-summer' : term.name == 'Summer Term', 'term-autumn' : term.name == 'Autumn Term'}"
                  class="topbarCalender"
                  ></span>
            <span class="calendarbar" [ngClass]="{'bolded-Text' : view == 'term' && term.name == currentTerm.name}">{{term.name}}</span>
          </div>
        <div fxFlex="16"
             fxFlex.sm="25"
             fxLayoutAlign="center center">
          <span style="background-color: #ECD4FF;  border: 1px solid #A73DF9;"
                class="topbarCalender"></span>
          <span class="calendarbar">Non Term Time</span>
        </div>
        <div fxFlex="16"
             fxFlex.sm="25"
             fxLayoutAlign="center center">
          <span style="background-color: #FF9F1080;  border: 1px solid #FF9F10;"
                class="topbarCalender"></span>
          <span class="calendarbar">Holidays</span>
        </div> 
        <div fxFlex="16"
             fxFlex.sm="25"
             fxLayoutAlign="center center">
            <span style="background-color: #ff9a8e;  border: 1px solid #ca1818;"
                  class="topbarCalender"></span>
            <span class="calendarbar">NES Period</span>
        </div> 
      </div>


      <div fxFlex="100"
           style="margin-top: 40px; padding: 20px;">
        <div [ngSwitch]="view">
          <mwl-calendar-month-view *ngSwitchCase="'month'"
                                   [viewDate]="viewDate"
                                   [events]="events"
                                   weekStartsOn="1"
                                   [refresh]="refresh"
                                   (dayClicked)="dayClicked($event)"
                                   (eventClicked)="handleEvent('Clicked', $event.event)"
                                   (eventTimesChanged)="eventTimesChanged($event)">
          </mwl-calendar-month-view>
          <app-calender *ngSwitchCase="'term'"
                        [viewDate]="viewDate"
                        [view]="view"
                        [terms]="terms"
                        [nesEligibleWeeks]="nesEligibleWeeks"
                        [currentTerm]="currentTerm"
                        (refreshData)="callApi()"></app-calender>
          <app-calender *ngSwitchCase="'year'"
                        [viewDate]="viewDate"
                        [view]="view"
                        [terms]="terms"
                        [nesEligibleWeeks]="nesEligibleWeeks"
                        [currentTerm]="currentTerm"
                        (refreshData)="callApi()"></app-calender>

        </div>
      </div>
    </div>


<!-- <mat-card>
    
      
      <h3>
        Edit events
        <button class="btn btn-primary float-right" (click)="addEvent()">
          Add new
        </button>
        <div class="clearfix"></div>
      </h3>
      
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Title</th>
              <th>Primary color</th>
              <th>Secondary color</th>
              <th>Starts at</th>
              <th>Ends at</th>
              <th>Remove</th>
            </tr>
          </thead>
      
          <tbody>
            <tr *ngFor="let event of events">
              <td>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="event.title"
                  (keyup)="refresh.next()"
                />
              </td>
              <td>
                <input
                  type="color"
                  [(ngModel)]="event.color.primary"
                  (change)="refresh.next()"
                />
              </td>
              <td>
                <input
                  type="color"
                  [(ngModel)]="event.color.secondary"
                  (change)="refresh.next()"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  mwlFlatpickr
                  [(ngModel)]="event.start"
                  (ngModelChange)="refresh.next()"
                  [altInput]="true"
                  [convertModelValue]="true"
                  [enableTime]="true"
                  dateFormat="Y-m-dTH:i"
                  altFormat="F j, Y H:i"
                  placeholder="Not set"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  mwlFlatpickr
                  [(ngModel)]="event.end"
                  (ngModelChange)="refresh.next()"
                  [altInput]="true"
                  [convertModelValue]="true"
                  [enableTime]="true"
                  dateFormat="Y-m-dTH:i"
                  altFormat="F j, Y H:i"
                  placeholder="Not set"
                />
              </td>
              <td>
                <button class="btn btn-danger" (click)="deleteEvent(event)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <ng-template #modalContent let-close="close">
        <div class="modal-header">
          <h5 class="modal-title">Event action occurred</h5>
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            Action:
            <pre>{{ modalData?.action }}</pre>
          </div>
          <div>
            Event:
            <pre>{{ modalData?.event | json }}</pre>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" (click)="close()">
            OK
          </button>
        </div>
      </ng-template>
</mat-card> -->