<mat-card class="row-margin-tb card-dark">
    <mat-card style="padding: 0px !important;">
            <app-app-headers (outPutHeaders)="openDialog()" [headerProps]="buttonHeaderProps"></app-app-headers>
        <mat-tab-group [selectedIndex]="selected"
                       (selectedIndexChange)="selectedIndex($event)">
            <mat-tab label="Users and Roles">
                <ng-template matTabContent>
                    <app-user-roles></app-user-roles>
                </ng-template>
            </mat-tab>
            <mat-tab label="Roles and Privilages">
                <ng-template matTabContent>
                    <app-roles-privilages></app-roles-privilages>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

    </mat-card>
</mat-card>