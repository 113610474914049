<mat-dialog-content style="max-height: 100vh;">
    <div style="width: 400px;">
        <div fxLayout="row wrap"
             fxLayoutAlign="center center"
             >
            <div fxFlex="5">
            </div>
            <div fxFlex="90">
                <div fxLayout="row wrap"
                fxLayoutAlign="space-between center"
                class="top-heading text-bolder text-color mb-30"  >
                <div fxFlex>
                Booking Type
                </div> 
            </div>

                <form [formGroup]="form">
                    <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     style="margin-top: 10px;">
                     <!-- <label style="margin-left: 5px;">Please Select</label> -->
                    <div fxFlex="100">
                        <mat-radio-group 
                            aria-labelledby="Select an option"
                            formControlName="bookingType">
                            <mat-radio-button style="width: 45%" value="adhoc_session">
                            Adhoc Session
                            </mat-radio-button>
                            <mat-radio-button style="width: 46%" value="recurring_sessions">
                            Recurring Sessions
                            </mat-radio-button>
                            <mat-radio-button class="mt-10" style="width: 45%;" value="multiple_sessions">
                            Multiple Sessions
                            </mat-radio-button>
                        </mat-radio-group>                            
                    </div>
                    </div>

                </form>
                
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="5px"
                     style="height:100px">
                    <div fxFlex>
                        <button mat-button
                                style="width: 90%;background-color: rgb(193, 187, 185);"
                                type="button"
                                class="btn-custom-form"
                                (click)="onCancelClick()">Cancel</button>
                    </div>
                    <div fxFlex>
                        <button mat-button
                                style="width: 90%;"
                                class="btn-custom-form yellow"
                                type="button"
                                (click)="onSubmitClick()">Next</button>
                    </div>
                </div>
            </div>
            <div fxFlex="5">
            </div>
        </div>
    </div>
</mat-dialog-content>
