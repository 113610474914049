<form [formGroup]="Form"
      (ngSubmit)="onSubmit()">
  <mat-card class="card">
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between center"
         fxLayoutGap="15px"
         class="top-heading text-bolder text-color">
      {{ 'Process Credits' | translate }}

      <button mat-button class="editBtn" (click)="goToEdit()" *ngIf="type == 'view' && editPermit">
        <mat-icon class="editIcon">edit</mat-icon>
        {{ 'Edit' | translate }}
      </button>
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="15px"
         class="top-heading text-bold ">
      {{childName}}
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between center"
         fxLayoutGap="10px"
         class="row-wrap-margins" style="width: 58%">
      <div fxFlex.xl="28"
           fxFlex.lg="40"
           fxFlex.md="40"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
           <mat-form-field fxFlex="290px"
           fxFlex.sm="100"
           fxFlex.xs="100">
     <mat-label>{{ 'Select Date Range' | translate }} <span style="color: red">*</span></mat-label>
     <mat-date-range-input [max]="currentDate"
                 (click)="FormPicker.open()"
                 [rangePicker]="FormPicker">
       <input matStartDate
            readonly
            placeholder="{{ 'Start date' | translate }}"
            formControlName="start">
       <input matEndDate
            readonly
            (dateChange)="valueChange()"
            placeholder="{{ 'End date' | translate }}"
            formControlName="end">
     </mat-date-range-input>
     <mat-icon style="cursor: pointer;"
           *ngIf="Form.get('start').value && Form.get('end').value"
           matSuffix
           (click)="onClear()">close</mat-icon>
     <mat-datepicker-toggle matPrefix
                  [for]="FormPicker"></mat-datepicker-toggle>
     <mat-date-range-picker (closed)="onClosed()"
                  #FormPicker></mat-date-range-picker>

     <mat-error *ngIf="getField('end')">{{getErrorMessage('end')| translate}}</mat-error>

   </mat-form-field>
        <!-- <mat-form-field (click)="desired.open()">
          <mat-label>Date</mat-label>
          <input matInput
                 (dateChange)="dateChangeStatic(Form, 'date', $event)"
                 formControlName="date"
                 [matDatepicker]="desired">
          <mat-icon matSuffix>
            <img [src]="calendar"
                 style="margin-bottom: 0px;
                          margin-right: 10px; width: 16px; vertical-align: baseline;
                           cursor: pointer;">
          </mat-icon>
          <mat-datepicker #desired></mat-datepicker>
        </mat-form-field> -->

        <!-- <mat-form-field (click)="desired.open()">
          <mat-label>Start Date</mat-label>
          <input matInput (dateChange)="dateChangeStatic(Form, 'startDate', $event)"
             formControlName="startDate" [matDatepicker]="desired">
          <mat-icon matSuffix>
            <img [src]="calendar" style="margin-bottom: 0px;
                          margin-right: 10px; width: 16px; vertical-align: baseline;
                           cursor: pointer;">
          </mat-icon>
          <mat-datepicker #desired></mat-datepicker>
        </mat-form-field> -->
      </div>
      <!-- <div fxFlex.xl="28"
           fxFlex.lg="40"
           fxFlex.md="40"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">

           <mat-form-field>
            <mat-label>End Date</mat-label>
            <input matInput

                   (dateChange)="dateChangeStatic(Form, 'endDate', $event)"
                   formControlName="endDate"
                   [matDatepicker]="desired1"
                   [disabled]="!Form.get('startDate').value"
                   [min]="Form.get('startDate').value">
            <mat-icon matSuffix (click)="desired1.open()">
              <img [src]="calendar"
                   style="margin-bottom: 0px;
                            margin-right: 10px; width: 16px; vertical-align: baseline;
                             cursor: pointer;">
            </mat-icon>
            <mat-datepicker #desired1></mat-datepicker>
          </mat-form-field>
      </div> -->
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between center"
         class="row-wrap-margins">
      <div fxFlex="70"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Memo' | translate }}</mat-label>
          <textarea style="resize: none; min-height: 150px;"
                    matInput
                    type="text"
                    formControlName="memo"
                    required
                    maxlength="500"
                    #memo></textarea>
          <mat-hint align="end"
                    style="margin-top: 15px;">{{memo.value?.length || 0}}/500</mat-hint>
          <mat-error>{{getErrorMessage('memo')| translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between center"
         class="row-wrap-margins">
      <div fxFlex.xl="23"
           fxFlex.lg="25"
           fxFlex.md="25"
           fxFlex.sm="45"
           fxFlex.xs="100">
        <mat-form-field>
          <mat-label style="padding-left: 11px;">{{ 'Amount' | translate }}</mat-label>
          <input matInput
                 placeholder="0.00"
                 class="ml-15"
                 formControlName="matAmount"
                 mask="0*.00"
                 (blur)="onBlurEvent($event)"
                 required>
          <mat-error *ngIf="getField('matAmount')">{{getErrorMessage('matAmount')| translate}}</mat-error>
          <span class="pound-left" style="top: 16px;" matSuffix>£&nbsp;</span>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row wrap"
         fxLayoutAlign="start start"
         fxLayoutGap="15px"
         class="row-margin">
         <div fxFlex="150px" *ngIf="type == 'edit'">
          <button (click)="onCancel()"
                  type="button"
                  mat-button
                  [style.background-color]="'#C1BBB9'"
                  class="btn-custom-form">
                  {{ 'Cancel' | translate }}
          </button>
      </div>
      <div fxFlex="150px">
        <button (click)="onSaveCSV()"
                *ngIf="!disableInput"
                type="button"
                mat-button
                style="background-color: #F36F40;"
                class="btn-custom-form">
                {{this.csvButton?.buttonLabel | translate}}
        </button>
      </div>
      <div fxFlex="150px"
           *ngIf="!disableInput">
        <button (click)="onSubmit()"
                type="button"
                mat-button
                class="btn-custom-form">
          {{this.footerProps?.buttonLabel | translate}}
        </button>
      </div>
      <div fxFlex="150px">
        <button *ngIf="!disableInput"
                type="button"
                mat-button
                (click)="clearForm()"
                class="btn-custom-form">
           {{ 'Clear' | translate }}
        </button>
      </div>
    </div>
  </mat-card>

</form>
