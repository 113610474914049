<mat-dialog-content style="max-height: 100vh;">
    <div fxLayout="row wrap" style="min-width: 600px;"
        fxLayoutAlign="center start"
        >
    <div fxFlex="100">
        <div fxLayout="row wrap"
        fxLayoutAlign="space-between center"
        class="top-heading text-bolder text-color mt-0">

            <div style="font-size: 18px;">
                Booking Pattern Change
            </div> 
        
        </div>
        
     </div>
    <div fxFlex="100">
        <div fxLayout="row wrap"
             fxLayoutAlign="space-between center"
             class="top-heading text-bolder text-color mt-0">
        
                <div style="font-size: 14px;color: black;">
                Are you sure, you want to change existing booking pattern of this recurring booking to updated booking pattern?
                </div>
        </div>
    </div>

    <div fxFlex="47" class="mt-10">
    <!-- <mat-card class="py-0"> -->
     <div>
        <div class="top-header">
            <h2>Existing Booking pattern</h2>
        
            </div>
        
            <div >
                <div  style="background-color:#f7f6f6;padding: 0px 12px;">
                    <div class="room-detail">
                
                        <div style="font-weight: 600;">
                            {{getRoomName(initialChildDetail.roomId)}}
                        </div>
                        <div>
                            {{ getValidityLabel(initialChildDetail.validityType) }}
                        </div>
                        </div>
                        <div class="room-detail">
                
                            <div>
                                <span style="font-weight: 600;">Start Date : </span><span>{{initialChildDetail.joiningDate | date: 'dd/MM/yyyy'}}</span>
                            </div>
                            <div>
                                <span style="font-weight: 600;">End Date : </span><span>{{initialChildDetail.leavingDate ? (initialChildDetail.leavingDate | date: 'dd/MM/yyyy') : '-'}}</span>
                            </div>
                            </div>
                            <div class="room-detail">
                                <div *ngIf="initialChildDetail.repeatPeriod && initialChildDetail.repeatEvery && initialChildDetail.changeFrequency">
                                    <p class="info">Occurs every {{ getNumberWithSuffix(initialChildDetail.repeatEvery) }}  
                                    <span> {{ initialChildDetail.repeatPeriod }} </span>
                                    <span *ngIf="initialChildDetail.leavingDate"> 
                                        until {{ initialChildDetail.leavingDate | date: 'dd/MM/yyyy' }} 
                                    </span> 
                                    </p>
                                </div>
                                </div>                      
                </div>
                <mat-divider></mat-divider>
                <div >
                    <table>
                        <tr *ngFor="let session of tempSessions">
                            <div>
                            {{ session?.day | titlecase }} - {{getSessionName(session?.sessionId)}} ({{ session?.startTime | date : 'hh:mm a' }} - {{ session?.endTime | date : 'hh:mm a' }}) {{getAddonLabels(session.addOnsIds)}} 
                            </div>
                        </tr>             
                    </table>
                </div>
            </div>
     </div>   



    <!-- </mat-card> -->


    </div>
    <div style="height: 262px;display: flex;align-items: center;justify-content: center;" fxFlex="6">
      <img class="arrow-img" src="assets/images/sdn/arrow-pattern.png" alt="image">
    </div>
    <div fxFlex="47" class="mt-10">
        <!-- <mat-card class="py-0"> -->
        <div>
            <div class="top-header">
                <h2>Updated Booking pattern</h2>
                
                </div>
        
                <div >
                    <div  style="background-color:#f7f6f6;padding: 0px 12px;">
                        <div class="room-detail">
                    
                            <div style="font-weight: 600;">
                                {{getRoomName(childDetail.roomId)}}
                            </div>
                            <div>
                                {{ getValidityLabel(childDetail.validityType) }}
                            </div>
                            </div>
                            <div class="room-detail">
                    
                                <div>
                                    <span style="font-weight: 600;">Start Date : </span><span>{{childDetail.joiningDate | date: 'dd/MM/yyyy'}}</span>
                                </div>
                                <div>
                                    <span style="font-weight: 600;">End Date : </span><span>{{childDetail.leavingDate ? (childDetail.leavingDate | date: 'dd/MM/yyyy') : '-'}}</span>
                                </div>
                                </div>
                                <div class="room-detail">
                                    <div *ngIf="childDetail.repeatPeriod && childDetail.repeatEvery && childDetail.changeFrequency">
                                        <p class="info">Occurs every {{ getNumberWithSuffix(childDetail.repeatEvery) }}  
                                        <span> {{ childDetail.repeatPeriod }} </span>
                                        <span *ngIf="childDetail.leavingDate"> 
                                            until {{ childDetail.leavingDate | date: 'dd/MM/yyyy' }} 
                                        </span> 
                                        </p>
                                    </div>
                                    </div>               
                    </div>
                    <mat-divider></mat-divider>
                    <div >
                        <table>
                            <tr *ngFor="let session of sessionDetails">
                                <div>
                                {{ session?.day | titlecase }} - {{getSessionName(session?.sessionId)}} ({{ session?.startTime | date : 'hh:mm a' }} - {{ session?.endTime | date : 'hh:mm a' }}) {{getAddonLabels(session.addOnsIds)}} 
                                </div>
                            </tr>             
                        </table>
                    </div>
                </div>
        </div>    

        
        <!-- </mat-card> -->
        
    
    </div>

    
    </div>
    <div fxLayout="row wrap" style="min-width: 600px;"

    >
    <div fxFlex="45">

        
        <div *ngIf="allGuardians.length != 0" class="mt-4 invoice" >
            <h2>Invoice To</h2>     
            <p *ngFor="let item of initialChildDetail.invoiceSplittingDetails"> {{getGuardianName(item.guardian)}} (Amount paid by this payee {{ getPercent(item.amountPaidPercentage)}} %)</p>
            <p *ngIf="initialChildDetail.invoiceSplittingDetails.length == 0" style="color: grey;"> No Record</p>
        </div>
    </div>
        <div fxFlex="10">

        </div>
        <div fxFlex="45">

        
            <div *ngIf="allGuardians.length != 0" class="mt-4 invoice" >
                <h2>Invoice To</h2>
                <p *ngFor="let item of childDetail.invoiceSplittingDetails"> {{getGuardianName(item.guardian)}} (Amount paid by this payee {{ getPercent(item.amountPaidPercentage)}} %)</p>
                <p *ngIf="childDetail.invoiceSplittingDetails.length == 0" style="color: grey;"> No Record</p>
            </div>
            </div>
    </div>
    <div fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="5px"
        style="height:100px">

            <div fxFlex="20">
                <button mat-button
                style="width: 90%;background-color: rgb(193, 187, 185);"
                type="button"
                class="btn-custom-form"
                (click)="onCancelClick()">Cancel</button>
            </div>

            <div fxFlex="20">
                <button mat-button
                style="width: 90%;"
                class="btn-custom-form yellow"
                type="button"
                (click)="onSubmitClick()">Confirm</button>
                
            </div>
    </div>
   
</mat-dialog-content>
