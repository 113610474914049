<mat-card class="row-margin-tb card-dark">
  <mat-card >

      <!-- <app-app-headers [headerProps]="buttonHeaderProps"></app-app-headers> -->

      <mat-tab-group>
        <mat-tab label="Active Children">
          <ng-template matTabContent>
            <app-active-children  [patternTosend]="patternKpi" [idToSend]="idToSend"></app-active-children>
          </ng-template>
        </mat-tab>
        <mat-tab label="All Children">
          <ng-template matTabContent>
            <app-all-children [idToSend]="idToSend"></app-all-children>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </mat-card>
