<form [formGroup]="Form">
  <mat-card>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px" class="top-heading text-bolder text-color">
      {{title}}
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" class="top-heading text-bold">
      {{subTitle}}
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px" class="row-card-margins">
      <div fxFlex.xl="45" fxFlex.lg="45" fxFlex.md="45" fxFlex.sm="45" fxFlex.xs="100"
        class="text-normal-bold text-gray">
        <timepicker controlName="timeIn" (onSetTime)="onSetTime($event)" [disabled]="disableInput"
          [time]="Form.get('timeIn').value" [max]="Form.get('timeOut').value" [showError]="Form.get('timeIn').invalid"
          [required]="true" label="Time In"></timepicker>
      </div>

      <div fxFlex.xl="45" fxFlex.lg="45" fxFlex.md="45" fxFlex.sm="45" fxFlex.xs="100"
        class="text-normal-bold text-gray">
        <timepicker controlName="timeOut" (onSetTime)="onSetTime($event)" [disabled]="disableInput"
          [time]="Form.get('timeOut').value" [min]="Form.get('timeIn').value" [showError]="Form.get('timeOut').invalid"
          [required]="true" label="Time Out"></timepicker>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px" class="row-card-margins" *ngIf="attendanceType == 'staff'">
      <div fxFlex.xl="45" fxFlex.lg="45" fxFlex.md="45" fxFlex.sm="45" fxFlex.xs="100"
        class="text-normal-bold text-gray">
        <timepicker controlName="breakIn" (onSetTime)="onSetTime($event)" [disabled]="disableInput"
          [time]="Form.get('breakIn').value" [max]="Form.get('breakOut').value" [showError]="Form.get('breakIn').invalid"
          [required]="true" label="Break In"></timepicker>
      </div>

      <div fxFlex.xl="45" fxFlex.lg="45" fxFlex.md="45" fxFlex.sm="45" fxFlex.xs="100"
        class="text-normal-bold text-gray">
        <timepicker controlName="breakOut" (onSetTime)="onSetTime($event)" [disabled]="disableInput"
          [time]="Form.get('breakOut').value" [min]="Form.get('breakIn').value" [showError]="Form.get('breakOut').invalid"
          [required]="true" label="Break Out"></timepicker>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="15px" style="margin-top: 10px;">
      <!-- <div fxFlex="10"></div> -->
      <div fxFlex.sm="39" fxFlex.xs="100">
        <button (click)="onCancel()" type="button" mat-button style="margin-top: 10px;" class="btn-custom-form-small">
          Back
        </button>
      </div>
      <div *ngIf="type!=='view'" fxFlex.sm="39" fxFlex.xs="100">
        <button (click)="onSubmit()" type="button" mat-button style="margin-top: 10px;" class="btn-custom-form-small">
          Update
        </button>
      </div>
    </div>
  </mat-card>
</form>
