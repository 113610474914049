<form [formGroup]="Form"
      (ngSubmit)="onSubmit()">
    <mat-card class="card">
        <ng-container [formGroup]="childDoctorDetail">
            <div fxLayout="row wrap"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="15px"
                 class="top-heading text-bolder text-color">
                {{ 'GP Details' | translate }}
                <button mat-button class="editBtn" (click)="goToEdit()" *ngIf="type == 'view' && editPermit">
                    <mat-icon class="editIcon">edit</mat-icon>
                    {{ 'Edit' | translate }}
                </button>
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="space-between center"
                 class="row-wrap-margins">
                <div fxFlex.xl="28"
                     fxFlex.lg="32"
                     fxFlex.md="32"
                     fxFlex.sm="45"
                     fxFlex.xs="100"
                     class="col-flex-margins">
                    <mat-form-field>
                        <mat-label>{{ "Doctor's Name" | translate }}</mat-label>
                        <input matInput
                               type="text"
                               formControlName="doctorName"
                               minlength="2"
                               maxlength="36"
                               required
                               >
                        <mat-error *ngIf="getField('doctorName', childDoctorDetail)">{{getErrorMessage('doctorName', childDoctorDetail)| translate}}</mat-error>
                    </mat-form-field>
                </div>

                <div fxFlex.xl="28"
                     fxFlex.lg="32"
                     fxFlex.md="32"
                     fxFlex.sm="45"
                     fxFlex.xs="100"
                     class="col-flex-margins">
                    <mat-form-field>
                        <mat-label>{{ 'Telephone No' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               formControlName="workTelephoneNumber"
                               mask="9999999999"
                               minlength="8"
                               maxlength="10"
                               required
                               >
                               <mat-error *ngIf="getField('workTelephoneNumber', childDoctorDetail)">{{getErrorMessage('workTelephoneNumber', childDoctorDetail)| translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex.xl="28"
                     fxFlex.lg="32"
                     fxFlex.md="32"
                     fxFlex.sm="45"
                     fxFlex.xs="100"
                     class="col-flex-margins">
                    <mat-form-field>
                        <mat-label>{{ 'Surgery Name' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               formControlName="surgeryName"
                               >
                               <mat-error *ngIf="getField('surgeryName', childDoctorDetail)">{{getErrorMessage('surgeryName', childDoctorDetail)| translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex.xl="28"
                     fxFlex.lg="66"
                     fxFlex.md="66"
                     fxFlex.sm="45"
                     fxFlex.xs="100"
                     class="col-flex-margins">
                    <mat-form-field>
                        <mat-label>{{ 'Address' | translate }}</mat-label>
                        <input matInput
                               readonly
                               type="text"
                               (click)="openAddressDialog(childDoctorDetail)"
                               formControlName="addressLabel"
                               >
                        <mat-icon matSuffix>
                            <img [src]="location"
                                 style="margin-bottom: 0px;
                                margin-right: 10px; width: 14px; vertical-align: middle;">
                        </mat-icon>
                        <mat-error *ngIf="getField('address', childDoctorDetail)">{{getErrorMessage('address', childDoctorDetail)| translate}}</mat-error>
                        <span *ngIf="childDoctorDetail.get('address').value && !disableInput"
                              (click)="removeAddress()" 
                              class="removeAddress">{{ 'Remove' | translate }}</span>
                    </mat-form-field>
                </div>
            </div>
            
            <ng-container [formGroup]="childMedicalInformation">
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="15px"
                     class="top-heading text-small text-gray">
                    {{ 'Does child have any special diet, health problems or allergies?' | translate }}
                </div>
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="15px"
                     class="row-radio-margins">
                    <mat-radio-group aria-label="Select an option"
                                     formControlName="doesChildHaveAnySpecialDietOrHealthProblemOrAllergies">
                        <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="childMedicalInformation.controls['doesChildHaveAnySpecialDietOrHealthProblemOrAllergies'].value==true"
                     fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="15px"
                     class="row-wrap-margins">
                    <div fxFlex>
                        <mat-form-field>
                            <mat-label>{{ 'Please enter those details here.' | translate }}</mat-label>
                            <textarea matInput
                                      placeholder="{{ 'Add Details' | translate }}"
                                      formControlName="doesChildHaveAnySpecialDietOrHealthProblemOrAllergiesDescription"
                                     >
                </textarea>
                <!--  []="childMedicalInformation.controls['doesChildHaveAnySpecialDietOrHealthProblemOrAllergies'].value==true" -->
                <mat-error *ngIf="getField('doesChildHaveAnySpecialDietOrHealthProblemOrAllergiesDescription', childMedicalInformation)">{{getErrorMessage('doesChildHaveAnySpecialDietOrHealthProblemOrAllergiesDescription', childMedicalInformation)| translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
               
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="15px"
                     class="top-heading text-small text-gray">
                    {{ 'Are there any professionals involved with the child?' | translate }}
                </div>
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="15px"
                     class="row-radio-margins">
                    <mat-radio-group aria-label="Select an option"
                                     formControlName="areThereAnyProfessionalsInvolvedWithTheChild">
                        <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="childMedicalInformation.controls['areThereAnyProfessionalsInvolvedWithTheChild'].value==true"
                     fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="15px"
                     class="row-wrap-margins">
                    <div fxFlex>
                        <mat-form-field>
                            <mat-label>{{ 'Please enter those details here.' | translate }}</mat-label>
                            <textarea matInput
                                      placeholder="{{ 'Add Details' | translate }}"
                                      formControlName="areThereAnyProfessionalsInvolvedWithTheChildDescription"
                                      >
                </textarea>
                <mat-error *ngIf="getField('areThereAnyProfessionalsInvolvedWithTheChildDescription', childMedicalInformation)">{{getErrorMessage('areThereAnyProfessionalsInvolvedWithTheChildDescription', childMedicalInformation)| translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="top-heading text-bolder text-color">
                {{ 'Medical Information' | translate }}
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="space-between center"
                 class="row-wrap-margins">
                <div fxFlex.xl="28"
                     fxFlex.lg="32"
                     fxFlex.md="32"
                     fxFlex.sm="45"
                     fxFlex.xs="100"
                     class="col-flex-margins">
                    <mat-form-field>
                        <mat-label>{{ 'Health Visitor’s Name' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               formControlName="healthVisitorName"
                               minlength="2"
                               maxlength="36"
                               >
                               <mat-error *ngIf="getField('healthVisitorName', childDoctorDetail)">{{getErrorMessage('healthVisitorName', childDoctorDetail)| translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex.xl="28"
                     fxFlex.lg="66"
                     fxFlex.md="66"
                     fxFlex.sm="45"
                     fxFlex.xs="100"
                     class="col-flex-margins"
                     [formGroup]="childMedicalInformation">
                    <mat-form-field>
                        <mat-label>{{ 'Prescribed Medication Taken' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               formControlName="prescribedMedicationTokenDescription"
                               >
                               <mat-error *ngIf="getField('prescribedMedicationTokenDescription', childMedicalInformation)">{{getErrorMessage('prescribedMedicationTokenDescription', childMedicalInformation)| translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>

        <ng-container [formGroup]="childMedicalInformation">
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="top-heading text-small text-gray">
                {{ 'Is the child being treated at a hospital?' | translate }}
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-radio-margins">
                <mat-radio-group aria-label="Select an option"
                                 formControlName="isTheChildBeingTreatedAtAHospital">
                    <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="childMedicalInformation.controls['isTheChildBeingTreatedAtAHospital'].value==true"
                 fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-wrap-margins">
                <div fxFlex>
                    <mat-form-field>
                        <mat-label>{{ 'Please enter those details here.' | translate }}</mat-label>
                        <textarea matInput
                                  placeholder="{{ 'Add Details' | translate }}"
                                  formControlName="isTheChildBeingTreatedAtAHospitalDescription"
                                  >
                </textarea>
                <mat-error *ngIf="getField('isTheChildBeingTreatedAtAHospitalDescription', childMedicalInformation)">{{getErrorMessage('isTheChildBeingTreatedAtAHospitalDescription', childMedicalInformation)| translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="top-heading text-small text-gray">
                {{ 'Are your Child’s immunisations up to date?' | translate }}
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-radio-margins">
                <mat-radio-group aria-label="Select an option"
                                 formControlName="areChildImmunitiesUpToDate">
                    <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="childMedicalInformation.controls['areChildImmunitiesUpToDate'].value==true"
                 fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-wrap-margins">
                <div fxFlex>
                    <mat-form-field>
                        <mat-label>{{ 'Please enter those details here.' | translate }}</mat-label>
                        <textarea matInput
                                  placeholder="{{ 'Add Details' | translate }}"
                                  formControlName="areChildImmunitiesUpToDateDescription"
                                  >
                </textarea>
                <mat-error *ngIf="getField('areChildImmunitiesUpToDateDescription', childMedicalInformation)">{{getErrorMessage('areChildImmunitiesUpToDateDescription', childMedicalInformation)| translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
           
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="top-heading text-small text-gray">
                {{ 'Does the child have Early Year’s action or support?' | translate }}
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-radio-margins">
                <mat-radio-group aria-label="Select an option"
                                 formControlName="doesTheChildHaveEarlyYearActionOrSupport">
                    <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="childMedicalInformation.controls['doesTheChildHaveEarlyYearActionOrSupport'].value==true"
                 fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-wrap-margins">
                <div fxFlex>
                    <mat-form-field>
                        <mat-label>{{ 'Please enter those details here.' | translate }}</mat-label>
                        <textarea matInput
                                  placeholder="{{ 'Add Details' | translate }}"
                                  formControlName="doesTheChildHaveEarlyYearActionOrSupportDescription"
                                  >
                </textarea>
                <mat-error *ngIf="getField('doesTheChildHaveEarlyYearActionOrSupportDescription', childMedicalInformation)">{{getErrorMessage('doesTheChildHaveEarlyYearActionOrSupportDescription', childMedicalInformation)| translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="top-heading text-small text-gray">
                {{ 'Does the child have any distinguishing marks e.g., birthmarks?' | translate }}
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-radio-margins">
                <mat-radio-group aria-label="Select an option"
                                 formControlName="doesTheChildHaveAnyDistinguishingMarks">
                    <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="childMedicalInformation.controls['doesTheChildHaveAnyDistinguishingMarks'].value==true"
                 fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-wrap-margins">
                <div fxFlex>
                    <mat-form-field>
                        <mat-label>{{ 'Please enter those details here.' | translate }}</mat-label>
                        <textarea matInput
                                  placeholder="{{ 'Add Details' | translate }}"
                                  formControlName="doesTheChildHaveAnyDistinguishingMarksDescription"
                                  >
                </textarea>
                <mat-error *ngIf="getField('doesTheChildHaveAnyDistinguishingMarksDescription', childMedicalInformation)">{{getErrorMessage('doesTheChildHaveAnyDistinguishingMarksDescription', childMedicalInformation)| translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="top-heading text-small text-gray">
                {{ 'Was the child born prematurely?' | translate }}
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-radio-margins">
                <mat-radio-group aria-label="Select an option"
                                 formControlName="wasTheChildBornPrematurely">
                    <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="childMedicalInformation.controls['wasTheChildBornPrematurely'].value==true"
                 fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-wrap-margins">
                <div fxFlex>
                    <mat-form-field>
                        <mat-label>{{ 'Please enter those details here.' | translate }}</mat-label>
                        <textarea matInput
                                  placeholder="{{ 'Add Details' | translate }}"
                                  formControlName="wasTheChildBornPrematurelyDescription"
                                  >
                </textarea>
                <mat-error *ngIf="getField('wasTheChildBornPrematurelyDescription', childMedicalInformation)">{{getErrorMessage('wasTheChildBornPrematurelyDescription', childMedicalInformation)| translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
           
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="top-heading text-small text-gray">
                {{ 'Do parents have any concerns over the child’s development?' | translate }}
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-radio-margins">
                <mat-radio-group aria-label="Select an option"
                                 formControlName="doParentsHaveAnyConcernsOverTheChildDevelopment">
                    <mat-radio-button [value]="true">{{ 'Yes' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ 'No' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="childMedicalInformation.controls['doParentsHaveAnyConcernsOverTheChildDevelopment'].value==true"
                 fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 fxLayoutGap="15px"
                 class="row-wrap-margins">
                <div fxFlex>
                    <mat-form-field>
                        <mat-label>{{ 'Please Enter Details Here' | translate }}</mat-label>
                        <textarea matInput
                                  placeholder="{{ 'Add Details' | translate }}"
                                  formControlName="doParentsHaveAnyConcernsOverTheChildDevelopmentDescription">
                </textarea>
                <mat-error *ngIf="getField('doParentsHaveAnyConcernsOverTheChildDevelopmentDescription', childMedicalInformation)">{{getErrorMessage('doParentsHaveAnyConcernsOverTheChildDevelopmentDescription', childMedicalInformation)| translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <app-form-foooter *ngIf="type !== 'view'" (backEvent)="goBack()"
                              [footerProps]="footerProps"></app-form-foooter>
        </ng-container>
    </mat-card>
</form>