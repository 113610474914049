<mat-card style="position: relative;">
  <app-app-headers (outPutHeaders)="openDialog('add')"
                   [headerProps]="buttonHeaderProps"></app-app-headers>

  <!-- <button class="top-heading text-color" style="font-size:14px;position: absolute;top: 30px;right: 17px;" (click)="goToEdit()" *ngIf="type == 'view' && editPermit" mat-icon-button>
     Edit
  </button> -->
  
  <button mat-button style="position: absolute;top: 33px;right: 17px;" class="editBtn" (click)="goToEdit()" *ngIf="type == 'view' && editPermit">
    <mat-icon class="editIcon">edit</mat-icon>
    Edit
  </button>

  <div fxLayout="row wrap"
       fxLayoutAlign="start center" 
       fxLayoutGap="15px"
       class="row-card-margins">
    <div fxFlex="100"
         fxLayoutAlign="center center"
         style="margin-top: 30px; background-color: #F5F5F5; line-height: 40px;">
      <div fxFlex="35"
           fxLayoutAlign="center center">
        <span style="background-color: #00D100; cursor:pointer;"
              class="topbarCalender"></span>
        <span class="calendarbar">Scheduled Hours: {{patternKpi?.scheduledHours}} hrs.</span>
      </div>
      <div fxFlex="35"
           fxLayoutAlign="center center">
        <span style="background-color: #FF9F10; cursor:pointer;"
              class="topbarCalender"></span>
        <span class="calendarbar">Contracted Hours: {{patternKpi?.contractedHours}} hrs.</span>
      </div>
      <div fxFlex="35"
           fxLayoutAlign="center center">
        <span style="background-color: #FCB54E; cursor:pointer;"
              class="topbarCalender"></span>
        <span class="calendarbar">Remaining Hours: {{patternKpi?.remainingHours}} hrs.</span>
      </div>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Active Shift Pattern">
      <ng-template matTabContent>
        <app-active-pattern (onDeleteShift)="shiftDeleted()"
                            (onUpdateShift)="shiftUpdated()"
                            [patternTosend]="patternKpi"
                            [idToSend]="parentId"></app-active-pattern>
      </ng-template>
    </mat-tab>
    <mat-tab label="Archived Shift Pattern">
      <ng-template matTabContent>
        <app-archive-pattern [idToSend]="parentId"></app-archive-pattern>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>