<!-- <mat-progress-spinner [mode]="'indeterminate'" *ngIf="loading$ | async"></mat-progress-spinner> -->

<!-- <div *ngIf="loading$ | async" class="loader">
    <img src="../assets/images/sdn/SDN loader-lg.gif">
</div> -->

<div *ngIf="loading$ | async" class="loader overlay">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>


<!-- <div class="preloader">
<img src="assets/images/sdn/side-logo.png"
        style="width: 150px">
<p style="text-align: center; color: #FCB54E; font-weight: 600;">Loading<span class="one">.</span><span class="two">.</span><span class="three">.</span></p>
</div> -->
<router-outlet></router-outlet>
