<mat-card>
  <div fxLayout="row wrap"
       fxLayoutAlign="start center"
       fxLayoutGap="15px"
       class="top-heading text-bolder text-color">
    Select Children
  </div>
</mat-card>
<mat-card style="background-color: #ffffff26 !important; border: 0px !important;">
  <p class="text-normal"
     style="color: #4A4A4A; line-height: 8px; margin-bottom: -5px;">
    Enter Child Name or Parent Name
  </p>
  <filter-control [hasButton]="true"
                  [hasClearButton]="true"
                  [layoutAlign]="layoutAlign"
                  (filnalFilters)="filnalFilters($event)"
                  [hasSearch]="true"
                  [searchConfig]="headerProps?.searchConfig"
                  [fxFlexInSearch]="headerProps?.fxFlexIn"
                  [fxFlexInSearch]="'190px'"
                  [fxFlexInSort]="'170px'"
                  [hasCustomButton]="true"
                  [customButtonLabel]="customBtnLabel"
                  (buttonClickEvent)="onAddAdditional()"
                  [filterArray]="headerProps?.filterArray"></filter-control>
</mat-card>
<app-table-component (onPagination)="onPagination($event)"
                     (selectedItem)="actionButtonOutput($event)"
                     (emitSelectedCheckBox)="onSelectedCheckBoxEmit($event)"
                     (emitSelectedCheckBoxAll)="onSelectedCheckBoxAllEmit($event)"
                     (emitHeaderCheckBoxValue)="onHeaderCheckBoxValueEmit($event)"
                     [props]="tableConfigAndProps"
                     [hasHeaderCheckBox]="true"></app-table-component>

<mat-card style="border-radius: 0px !important;">
  <app-form-foooter (saveInfoEvent)="onAddAdditional()"
                    (clearEvent)="clearForm()"
                    (backEvent)="goBack()"
                    [footerProps]="footerProps"></app-form-foooter>

</mat-card>