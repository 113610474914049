<div style="width: 500px;">
  <div class="text-color" style="font-size: 24px;text-align: center;">{{alertData.heading | translate}}</div>

  <div 
    style="font-size: 13px; color: rgb(64, 64, 64); text-align: center; margin-top: 20px; padding: 0 30px;">
    {{ alertData.message | translate }}
  </div>
  <!-- <div *ngIf="showLoading" style="text-align: center">
        <img *ngIf="isLoading"width="50"height="50"src="assets/images/view-loader.gif">
     </div> -->
  <div fxLayout="row wrap" fxLayoutAlign="space-evenly start" fxLayoutGap="20px"
    style="text-align: right;margin-top: 20px;">
    <textarea id="reason" autofocus *ngIf="alertData.hasInput == true" [disabled]="isLoading"
      style="height: 100px; width: 400px;font-size: 20px;" maxlength="500" [(ngModel)]="reasonTExt "></textarea>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="space-evenly start" fxLayoutGap="20px"
    style="text-align: right;margin-top: 20px;">
    <button [ngClass]="alertData.leftButton.class" mat-button [disabled]="isLoading" type="button"
      (click)="onCancelClick()">{{alertData.leftButton.text | translate }}</button>

      <button [ngClass]="alertData.rightButton.class" mat-button *ngIf="alertData.type == 'ask'" [disabled]="isLoading || (alertData.hasInput && !reasonTExt)"
        type="button" (click)="onSubmitClick()">{{alertData.rightButton.text | translate}}</button>
  </div>
</div>
