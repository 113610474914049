<mat-card class="row-margin-tb card-dark">
    <mat-card style="padding: 0px !important;">
      <!-- <app-app-headers [headerProps]="buttonHeaderProps"></app-app-headers> -->
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           class="top-heading text-bolder text-color"
           style="padding: 30px 0px 0px 10px;">
      Calendar
      </div>
      <!-- <mat-tab-group >
        <mat-tab label="Calendar View">
          <ng-template matTabContent> -->
          
              <app-full-calander></app-full-calander>
        
          <!-- </ng-template>
        </mat-tab>
        <mat-tab label="Holidays">
          <ng-template matTabContent>
              <app-holidays></app-holidays>
              
          </ng-template>
        </mat-tab>
      </mat-tab-group> -->
    </mat-card>

  </mat-card>