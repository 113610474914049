<div fxLayout="row wrap"
     fxLayoutAlign="start end"
     fxLayoutGap="10px"
     style="margin:10px;">
          <div fxFlex="49" fxLayoutGap="10px">
            <div fxFlex="150px">
              <button (click)="onYearClick('past')"
                      type="button"
                      mat-button
                      [ngClass]=" {'btn-custom-day-friday': type!=='past' , 'btn-custom-form': type =='past' } "
                     >
                  Past Year
              </button>
          </div>
            <div fxFlex="150px">
                <button (click)="onYearClick('present')"
                        type="button"
                        mat-button
                        [ngClass]=" {'btn-custom-day-friday': type!=='present' , 'btn-custom-form': type=='present' } "
                       >
                    Current Year
                </button>
            </div>
            <div fxFlex="150px">
              <button (click)="onYearClick('future')"
                      type="button"
                      mat-button
                      [ngClass]=" {'btn-custom-day-friday': type!=='future' , 'btn-custom-form': type=='future' } "
                     >
                  Future
              </button>
          </div>
          </div>
        <div fxFlex="49" fxLayoutAlign="end center">
            <div fxFlex="30" fxLayoutAlign="center center">
                <span style="background-color: #ECD4FF;  border: 1px solid #DBACFF;" class="topbarCalender"></span>
                <span class="calendarbar">Non Term Time</span>
            </div>
            <div fxFlex="30" fxLayoutAlign="center center">
                <span style="background-color: #FF9F1080;  border: 1px solid #FF9F10;" class="topbarCalender"></span>
                <span class="calendarbar">Holiday</span>
            </div>
            <div fxFlex="30"
                 fxFlex.sm="25"
                 fxLayoutAlign="center center">
                <span style="background-color: #ff9a8e;  border: 1px solid #ca1818;"
                        class="topbarCalender"></span>
                <span class="calendarbar">NES Holidays</span>
       </div> 
        </div>
</div>
<mat-divider></mat-divider>

<!-- <div fxLayout="row wrap"
     fxLayoutAlign="start end"
     fxLayoutGap="5px"
     style="margin: 30px 0px 10px 30px;"
     *ngIf="type=='past'">
    <div fxFlex="100px">         //commented Div
        <button (click)="onYearClick('2018')"
                type="button"
                mat-button
                [ngClass]=" {'btn-year-border': year!=='2018' , 'btn-custom-year': year=='2018' } "
               >
            {{viewDate.getFullYear()-3}}
        </button>
    </div>
    <div fxFlex="100px">
        <button (click)="onYearClick('2019')"
                type="button"
                mat-button
                [ngClass]=" {'btn-year-border': year!=='2019' , 'btn-custom-year': year =='2019' } "
               >
               {{viewDate.getFullYear()-2}}
        </button>
    </div>
    <div fxFlex="100px">                //commented Div
        <button (click)="onYearClick('2020')"
                type="button"
                mat-button
                [ngClass]=" {'btn-year-border': year!=='2020' , 'btn-custom-year': year =='2020' } "
               >
               {{viewDate.getFullYear()-1}}
        </button>
    </div>
    <div fxFlex="100px">
        <button (click)="onYearClick('2021')"
                type="button"
                mat-button
                [ngClass]=" {'btn-year-border': year!=='2021' , 'btn-custom-year': year ==viewDate.getFullYear() } "
               >
               {{viewDate.getFullYear()}}
        </button>
    </div>
</div> -->


<mat-divider></mat-divider>
<!-- <mat-devider></mat-devider> -->

<app-table-component (onPagination)="onPagination($event)"
                     (selectedItem)="actionButtonOutput($event)"
                     [props]="tableConfigAndProps"></app-table-component>
