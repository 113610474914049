<!-- <button (click)="openDialog()">open dialog</button> -->
<div fxLayout="row" fxLayoutAlign="space-between start">

    <div fxFlex="55">
        <angular-calendar-year-view [themecolor]="themecolor"
                                    [events]="events"
                                    [viewDate]="viewDate"
                                    (openDialogg)="openDialog($event)"
                                    [view]="view"
                                    [currentTerm]="currentTerm"
                                    (eventClicked)="eventClicked($event)"
                                    (actionClicked)="actionClicked($event)"></angular-calendar-year-view>
    </div>
    <div fxFlex="45">
        <p class="text-normal head mb-20">
            <strong> Events </strong>
        </p>
        <app-table-component *ngIf="dataItems.length != 0" (onPagination)="onPagination($event)"
                     (selectedItem)="actionButtonOutput($event)"
                     [nextLineText]="true"
                     [props]="tableConfigAndProps" height="auto"></app-table-component>

        <p class="text-small total" *ngIf="dataItems.length == 0">No Events Found</p>
        <div>
            <hr>
            <p style="color: #687784;" class="text-small total">Number of weeks eligible for Stretching: {{ nesEligibleWeeks }}</p>
        </div>             
    </div>
    
</div>
