<div class="top-heading text-bolder text-color mb-20"> 
    Override Recurring Session(s)
</div>

<div class="top-heading text-bold" style="margin-top: 13px">
    Select the recurring session you want to override with the adhoc session you created.
</div>

<div style="overflow-x: auto;" class="mt-30">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat">

        <ng-container matColumnDef="select">
            <th style="padding-top: 19px !important;" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
            </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="day">
          <th mat-header-cell *matHeaderCellDef> Day </th>
          <td mat-cell *matCellDef="let element"> {{ element.day | titlecase }} <br> ({{ joiningDate | date: 'dd MMM yyyy' }}) </td> 
        </ng-container>

      
        <ng-container matColumnDef="room">
          <th mat-header-cell *matHeaderCellDef> Room </th>
          <td mat-cell *matCellDef="let element;let i = index;"> 

            {{ element.roomName }}
          </td>
        </ng-container>

      
        <ng-container matColumnDef="session">
          <th mat-header-cell *matHeaderCellDef> Session </th>
          <td mat-cell *matCellDef="let element; let i = index"> 
            {{ element.sessionName }} <br>
            {{ element.startTimeDisplay | date : 'hh:mm a' }} - {{ element.endTimeDisplay | date : 'hh:mm a' }}
          </td>
        </ng-container>

        
        <ng-container matColumnDef="addOns">
            <th mat-header-cell *matHeaderCellDef> Add Ons </th>
            <td class="small" mat-cell *matCellDef="let element; let i = index">             
               {{ element.addOnDisplay }}
            </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div fxLayout="row wrap"
     fxLayoutAlign="end center"
     fxLayoutGap="5px"
     style="height:100px">
    <div fxFlex="10">
    <button mat-button
            style="width: 90%;background-color: rgb(193, 187, 185);"
            type="button"
            class="btn-custom-form"
            (click)="onCancelClick()">Cancel</button>
    </div>
    <div fxFlex="10">
    <button mat-button
            style="width: 90%;"
            class="btn-custom-form yellow"
            type="button"
            (click)="onSubmitClick()">Save</button>
    </div>
</div>
