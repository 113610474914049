<div fxLayout="row wrap"
     fxLayoutAlign="space-between center"
     fxLayoutGap="15px"
     class="top-heading text-bolder text-color">
  {{ 'Select Child' | translate }}

</div>

<form [formGroup]="Form"
      (ngSubmit)="onSubmit()">
  <div fxLayout="row wrap"
       fxLayoutAlign="start center"
       fxLayoutGap="15px"
       class="row-wrap-margins">
    <div fxFlex.xl="45"
         fxFlex.lg="45"
         fxFlex.md="45"
         fxFlex.sm="45"
         fxFlex.xs="100"
         class="col-flex-margins"
         *ngIf="formType != 'update'">
      <!-- <mat-form-field>
        <mat-label>Select Child</mat-label>
        <mat-select class="height-40"
                    formControlName="childId"
                    required>
          <mat-option *ngFor="let option of unlinkChildrens"
                      [value]="option.childId"
                      style="height: 70px;">
            <ng-container>
              <b>{{option?.data?.name}}</b>
              <p style="margin-bottom: 0px;font-size: 12px;"> {{option?.data?.age}}</p>
              <p style="margin-bottom: 0px;font-size: 12px;"> {{option?.data?.roomName}}</p>
            </ng-container>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="getField('childId')">{{getErrorMessage('childId')| translate}}</mat-error>
      </mat-form-field> -->
      <mat-form-field>
        <mat-label>{{ 'Select Child' | translate }}<span style="color:red"> *</span></mat-label>
        <input type="text"
               aria-label="Number"
               matInput
               [formControl]="Form.get('childLabel')"
               [matAutocomplete]="auto">

        <mat-autocomplete 
                          #auto="matAutocomplete"
                          (optionSelected)="setChildId()">
          <mat-option *ngFor="let option of filteredUnlinkedChildrens"
                      style="height: 70px;"
                      [value]="option.data.name">
            <!-- <div>{{option.name}}</div> -->
            <!-- <div style="margin-top: -31px;font-size: 11px;">{{option.email}}</div> -->

            <ng-container>
              <b>{{option?.data?.name}}</b>
              <p style="margin-bottom: 0px;font-size: 12px;"> {{option?.data?.age}}</p>
              <p style="margin-bottom: 0px;font-size: 12px;"> {{option?.data?.roomName}}</p>
            </ng-container>
          </mat-option>
          <mat-option *ngIf="filteredUnlinkedChildrens.length == 0"
                      style="pointer-events: none;">
            <span>{{ 'There are no Unlinked Childrens in the system' | translate }}</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="getField('childId')">{{getErrorMessage('childId')| translate}}</mat-error>

      </mat-form-field>
    </div>

    <div fxFlex.xl="45"
         fxFlex.lg="45"
         fxFlex.md="45"
         fxFlex.sm="45"
         fxFlex.xs="100"
         class="col-flex-margins"
         *ngIf="formType == 'update'">
      <mat-form-field>
        <mat-label>{{ 'Child Name' | translate }}</mat-label>
        <input matInput
               type="text"
               readonly="true"
               formControlName="name">
      </mat-form-field>
    </div>

    <div fxFlex.xl="45"
         fxFlex.lg="45"
         fxFlex.md="45"
         fxFlex.sm="45"
         fxFlex.xs="100"
         class="col-flex-margins"
         [ngClass]="{'disabledRelationDiv': isRelationTypeOther}">
      <mat-form-field>
        <mat-label>{{ 'Select Relation to Child' | translate }}</mat-label>
        <mat-select class="height-40"
                    formControlName="guardianRelationId"
                    required>
          <mat-option *ngFor="let option of relations"
                      [value]="option.id">
            <ng-container>
              {{option.relationType}}
            </ng-container>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="getField('guardianRelationId')">{{getErrorMessage('guardianRelationId')| translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap"
       fxLayoutAlign="end end"
       fxLayoutGap="15px"
       class="row-margin">

    <div fxFlex="150px">
      <button type="button"
              mat-button
              (click)="onCancel()"
              class="btn-custom-form">
        {{ 'Cancel' | translate }}
      </button>
    </div>
    <div fxFlex="150px">
      <button type="submit"
              mat-button
              class="btn-custom-form">
        {{buttonLabel | translate}}
      </button>
    </div>
  </div>
</form>