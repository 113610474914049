<mat-card class="mat-elevation-z3 presets">
    <button
      *ngFor="let item of customPresets"
      mat-button
      [color]="selectedPreset == item ? 'primary' : 'grey'"
      (click)="selectRange(item)"
    >
      {{ item }}
    </button>
  </mat-card>

  <!-- <div id="hiddenDiv" (click)="resetSelection()">
    fff
  </div> -->
