<mat-card class="card">
  <form [formGroup]="Form"
        (ngSubmit)="onSubmit()">
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between center"
         fxLayoutGap="15px"
         class="top-heading text-bolder text-color">
      {{ 'Guardian Information' | translate }}
      <button mat-button
              class="editBtn"
              (click)="goToEdit()"
              *ngIf="type == 'view' && editPermit">
        <mat-icon class="editIcon">edit</mat-icon>
        {{ 'Edit' | translate }}
      </button>
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="15px"
         *ngIf="!hidden"
         class="top-heading text-small text-gray">
      {{ 'Guardian Type' | translate }}
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="15px">
      <div fxFlex.xl="50"
           fxFlex.lg="50"
           fxFlex.md="50"
           fxFlex.sm="50"
           fxFlex.xs="100"
           *ngIf="!hidden">
        <mat-radio-group aria-label="Select an option"
                         formControlName="guardianType">
          <mat-radio-button value="existing">{{ 'Existing Guardian' | translate }}</mat-radio-button>
          <mat-radio-button value="new">{{ 'New Guardian' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="15px"
         class="row-wrap-margins">


      <div *ngIf="Form.controls['guardianType'].value=='existing'"
           fxFlex.xl="35"
           fxFlex.lg="35"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Select Guardian' | translate }}<span style="color:red">*</span></mat-label>
          <input type="text"
                 aria-label="Number"
                 matInput
                 [formControl]="Form.get('guardianName')"
                 [matAutocomplete]="auto">

          <mat-autocomplete autoActiveFirstOption
                            #auto="matAutocomplete"
                            (optionSelected)="setGuardianId()">
            <mat-option *ngFor="let option of filteredGuardians"
                        [value]="option.name">
              <div>{{option.name}}</div>
              <div style="    margin-top: -31px;font-size: 11px;">{{option?.email || '--'}}</div>
            </mat-option>
            <mat-option *ngIf="filteredGuardians.length == 0"
                        style="pointer-events: none;">
              <span>{{ 'There are no guardians in the system' | translate }}</span>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="getField('guardianName')">{{getErrorMessage('guardianName')| translate}}</mat-error>

        </mat-form-field>
      </div>
      <div fxFlex.xl="35"
           fxFlex.lg="32"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins"
           *ngIf="Form.controls['guardianType'].value  !='existing'">
        <mat-form-field>
          <mat-label>{{ 'Account Type' | translate }}</mat-label>
          <mat-select class="height-40"
                      (ngModelChange)="checkForType()"
                      formControlName="type"
                      required>
            <mat-option *ngFor="let option of relationShip"
                        [value]="option.key">
              {{option.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="getField('type')">{{getErrorMessage('type')| translate}}</mat-error>
        </mat-form-field>
      </div>



      <div fxFlex.xl="35"
           fxFlex.lg="32"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins"
           *ngIf="Form.controls['type'].value != 'other'">
        <!-- <mat-form-field>
          <mat-label>Relationship to child</mat-label>
          <mat-select class="height-40"
                      formControlName="guardianRelationId"
                      required>
            <mat-option *ngFor="let option of relations"
                        [value]="option.id">
              <ng-container>
                {{option.relationType}}
              </ng-container>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="getField('guardianRelationId')">{{getErrorMessage('relationToChild')| translate}}</mat-error>

        </mat-form-field> -->

        <mat-form-field>
          <mat-label>{{ 'Relationship to the child' | translate }}<span style="color: red;">*</span></mat-label>
          <input type="text"
                aria-label="Number"
                matInput
                [formControl]="Form.get('relationLabel')"
                [matAutocomplete]="auto2">
          <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="setRelationValue()">
            <mat-option *ngFor="let option of filteredRelations" [value]="option.relationType">
              {{option.relationType}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="getField('relationLabel')">{{getErrorMessage('relationLabel')| translate}}</mat-error>
      </mat-form-field>
      </div>

      <div *ngIf="Form.controls['guardianType'].value!='existing' && Form.controls['type'].value != 'other'"
           fxFlex.xl="25"
           fxFlex.lg="20"
           fxFlex.md="25"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Title' | translate }}<span style="color:red">*</span></mat-label>
          <mat-select class="height-40"
                      formControlName="title">
            <mat-option value="mr">Mr.</mat-option>
            <mat-option value="mrs">Mrs.</mat-option>
            <mat-option value="ms">Ms.</mat-option>
            <mat-option value="miss">Miss</mat-option>
          </mat-select>
          <mat-error *ngIf="getField('title')">{{getErrorMessage('title')| translate}}</mat-error>

        </mat-form-field>
      </div>

      <div *ngIf="Form.controls['guardianType'].value!='existing' && Form.controls['type'].value != 'other'"
           fxFlex.xl="35"
           fxFlex.lg="40"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Name' | translate }}<span style="color:red">*</span></mat-label>
          <input matInput
                 type="text"
                 formControlName="name">
          <mat-error *ngIf="getField('name')">{{getErrorMessage('name')}}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="Form.controls['guardianType'].value!='existing' && Form.controls['type'].value != 'other'"
           fxFlex.xl="32"
           fxFlex.lg="32"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="text-small text-gray">

        <mat-form-field (click)="desired2.open()"
                        style="margin-top: 10px;">
          <mat-label>{{ 'Date of Birth' | translate }}</mat-label>
          <input matInput
                 (dateChange)="setDateFormat(Form, 'dateOfBirth', $event)"
                 formControlName="dateOfBirth"
                 [matDatepicker]="desired2">
          <mat-error *ngIf="getField('dateOfBirth')">{{getErrorMessage('dateOfBirth')| translate}}</mat-error>
          <mat-icon matSuffix>
            <img [src]="calendar"
                 style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
          </mat-icon>
          <mat-datepicker #desired2></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="Form.controls['guardianType'].value!='existing'"
           fxFlex.xl="60"
           fxFlex.lg="62"
           fxFlex.md="55"
           fxFlex.sm="60"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Address' | translate }} <span style="color:red">*</span></mat-label>
          <input matInput
                 type="text"
                 (click)="openAddressDialog(Form)"
                 formControlName="addressLabel"
                 readonly>
          <!-- <mat-error *ngIf="getField('address')">{{getErrorMessage('address')| translate}}</mat-error> -->
          <span *ngIf="Form.get('address').value && !disableInput"
                (click)="removeAddress(Form)"
                class="removeAddress">{{ 'Remove' | translate }}</span>
          <mat-icon matSuffix>
            <img [src]="location"
                 style="margin-bottom: 0px;
                            margin-right: 10px; width: 13px; vertical-align: middle;">
          </mat-icon>
        </mat-form-field>

      </div>

      <div fxFlex.xl="35"
           fxFlex.lg="32"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins"
           *ngIf="Form.controls['type'].value == 'other' && Form.controls['guardianType'].value  =='new'">
        <mat-form-field>
          <mat-label>{{ 'Organization Name' | translate }}<span style="color:red">*</span></mat-label>
          <input matInput
                 type="text"
                 formControlName="organizationName">
          <mat-error *ngIf="getField('organizationName')">{{getErrorMessage('organizationName')| translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="Form.controls['guardianType'].value!='existing' && Form.controls['type'].value != 'other'"
           fxFlex.xl="32"
           fxFlex.lg="32"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Primary Language' | translate }}</mat-label>
          <input type="text"
                 aria-label="Number"
                 matInput
                 minlength="1"
                 maxlength="20"
                 [formControl]="Form.get('languageLabel')"
                 [matAutocomplete]="auto3">
          <mat-autocomplete autoActiveFirstOption
                            #auto3="matAutocomplete"
                            (optionSelected)="setLanguageId(Form)">
            <mat-option *ngFor="let option of filteredLanguages"
                        [value]="option.language">
              {{option.language}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="getField('languageLabel')">{{getErrorMessage('languageLabel')| translate}}</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="Form.controls['guardianType'].value!='existing' && Form.controls['type'].value != 'other'"
           fxFlex.xl="32"
           fxFlex.lg="30"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'National Insurance No.' | translate }}</mat-label>
          <input matInput
                 type="text"
                 formControlName="nationalInsuranceNumber"
                 placeholder="XX123456Q">
          <mat-error *ngIf="getField('nationalInsuranceNumber')">{{getErrorMessage('nationalInsuranceNumber')}}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="Form.controls['guardianType'].value!='existing' && Form.controls['type'].value != 'other'"
           fxFlex.xl="32"
           fxFlex.lg="30"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Home Landline No.' | translate }}</mat-label>
          <input matInput
                 type="text"
                 formControlName="landlineNo"
                 mask="9999999999"
                 minlength="8"
                 maxlength="10">
          <mat-error *ngIf="getField('landlineNo')">{{getErrorMessage('landlineNo')| translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="Form.controls['guardianType'].value!='existing'"
           fxFlex.xl="32"
           fxFlex.lg="32"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Mobile No.' | translate }}<span style="color:red">*</span></mat-label>
          <input matInput
                 type="text"
                 mask="9999999999"
                 minlength="8"
                 maxlength="10"
                 formControlName="mobileNumber">
          <mat-error *ngIf="getField('mobileNumber')">{{getErrorMessage('mobileNumber')}}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="Form.controls['guardianType'].value!='existing' && Form.controls['type'].value != 'other'"
           fxFlex.xl="32"
           fxFlex.lg="30"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Work Telephone No.' | translate }}</mat-label>
          <input matInput
                 type="text"
                 formControlName="workTelephoneNo"
                 mask="9999999999"
                 minlength="8"
                 maxlength="10">
          <mat-error *ngIf="getField('workTelephoneNo')">{{getErrorMessage('workTelephoneNumber')| translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="Form.controls['guardianType'].value!='existing'"
           fxFlex.xl="32"
           fxFlex.lg="30"
           fxFlex.md="32"
           fxFlex.sm="45"
           fxFlex.xs="100"
           class="col-flex-margins">
        <mat-form-field>
          <mat-label>{{ 'Email Address' | translate }}<span style="color:red">*</span></mat-label>
          <input matInput
                 type="text"
                 formControlName="email">
          <mat-error *ngIf="getField('email')">{{getErrorMessage('email')}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex.xl="100"
           fxFlex.lg="100"
           fxFlex.md="100"
           fxFlex.sm="100"
           fxFlex.xs="100"
           class="col-flex-margins" 
           *ngIf="Form.controls['type'].value != 'other'">
        <mat-checkbox formControlName="markedPrimary"
                      [disabled]="primaryDisabled"
                      class="example-margin">{{ 'Make Primary Guardian' | translate }}</mat-checkbox>
      </div>
    </div>


    <!-- (cancelEvent)="cancelForm()" -->
    <app-form-foooter *ngIf="type !== 'view'" (backEvent)="goBack()"
                      [footerProps]="footerProps"></app-form-foooter>
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between center"
         fxLayoutGap="15px"
         class="top-heading text-bolder text-color"
         *ngIf="type !=='new'">
      {{ 'Linked Guardians' | translate }}
    </div>
  </form>

</mat-card>
<ng-container *ngIf="childId && relations.length > 0">
  <app-linked-guardians *ngIf="parentId" [relations]="relations"
                        [childId]="parentId"
                        (listCount)="getCountList($event)"
                        (dataToEdit)="dataToEdit($event)"></app-linked-guardians>
</ng-container>
