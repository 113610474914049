<div class="wrapper">
    <mat-card
              style="background: #FFFFFF 0% 0% no-repeat padding-box !important; margin: 20px 20px 0px 20px; padding: 0 0 20px 0 !important;">
        <div fxLayout="row wrap"
             fxLayoutAlign="start center"
             fxLayoutGap="15px"
             class="top-heading text-bolder text-color"
             style="padding: 15px;">
            {{ 'Attendance View' | translate }}
        </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="space-between start"
             style="background: rgba(165, 174, 182, 0.15) 0% 0% no-repeat padding-box; padding: 10px 25px;">
            <div fxFlex="65"
                 fxFlex.sm="100"
                 fxFlex.xs="100">
                <div fxLayout="row wrap"
                     fxLayoutAlign="start start"
                     style="margin-top: 5px;">
                    <div fxFlex="30">
                        <div class="nus-heading">
                            {{title | translate}}
                        </div>
                        <div class="nus-data">
                            {{childDetails?.child?.firstName}} {{childDetails?.child?.lastName}}
                            {{childDetails?.staff?.firstName}} {{childDetails?.staff?.lastName}}
                        </div>
                    </div>
                    <div fxFlex="30">
                        <div class="nus-heading">
                            {{ 'Room' | translate }}
                        </div>
                        <div class="nus-data">
                            {{childDetails?.room?.name || 'N/A'}}
                        </div>
                    </div>
                    <!-- <div fxFlex="30" *ngIf="attendanceType == 'child'">
            <div class="nus-heading">
              Session
            </div>
            <div class="nus-data" style="text-transform: capitalize;">
              {{childDetails?.session?.name}}
            </div>
          </div> -->
                </div>
            </div>
            <div fxFlex="35"
                 fxFlex.sm="100"
                 fxFlex.xs="100">
                <form [formGroup]="Form"
                      style="margin-top: 5px;">
                    <mat-form-field fxFlex="280px"
                                    fxFlex.sm="100"
                                    fxFlex.xs="100">
                        <mat-label>{{ 'Date Range' | translate }}</mat-label>
                        <mat-date-range-input [max]="rangeMaxDate"
                                              (click)="FormPicker.open()"
                                              [rangePicker]="FormPicker">
                            <input matStartDate
                                   readonly
                                   (dateChange)="valueChanged()"
                                   placeholder="{{ 'Start date' | translate }}"
                                   formControlName="start">
                            <input matEndDate
                                   readonly
                                   (dateChange)="valueChanged()"
                                   placeholder="{{ 'End date' | translate }}"
                                   formControlName="end">
                        </mat-date-range-input>
                        <mat-icon style="cursor: pointer;"
                                  *ngIf="Form.get('start').value && Form.get('end').value"
                                  matSuffix
                                  (click)="onClear()">close</mat-icon>
                        <mat-datepicker-toggle matPrefix
                                               [for]="FormPicker"></mat-datepicker-toggle>
                        <mat-date-range-picker (closed)="onClosed()"
                                               #FormPicker></mat-date-range-picker>
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="space-between start"
             style="background-color: #F2F3F4;">
            <div fxFlex="100"
                 fxFlex.sm="100"
                 fxFlex.xs="100">
                <div fxLayout="row wrap"
                     fxLayoutAlign="start start"
                     class="month-change">
                    <div style="display: flex; width: 60%; place-content: center;">
                        <button mat-icon-button
                                [disabled]="type != 'detail'"
                                (click)="previousMonth()">
                            <mat-icon class="mat-18">keyboard_arrow_left</mat-icon>
                        </button>
                        <div style="padding: 10px;"><strong>{{date.startOf('month').format('DD-MMMM')}} -
                                {{date.endOf('month').format('DD-MMMM')}}</strong>
                        </div>
                        <button mat-icon-button
                                [disabled]="disabledNext"
                                (click)="nextMonth()">
                            <mat-icon class="mat-18">keyboard_arrow_right</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="space-between start">
            <div fxFlex="60"
                 fxFlex.sm="100"
                 fxFlex.xs="100">
                <div id='calendar-container'>
                    <div class='flex-container'>
                        <div *ngFor="let item of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
                             style="margin: 5px;font-weight: bold;"
                             class='calendar-days flex-container flex-center '>
                            {{item | translate}}
                        </div>
                    </div>
                    <div class='flex-container'>
                        <div *ngFor="let day of otherDaysArr">
                            <div (click)="onClickDate(day)"
                                 class='calendar-days flex-container'
                                 style="margin: 5px;"
                                 [class.boxShadow]="selectedDay == day"
                                 [class.currentDay]="day.current"
                                 [class.weekend]="day.value == 'weekend'"
                                 [class.off]="day.value == 'off'"
                                 [class.bank_holiday]="day.value == 'bank_holiday'"
                                 [class.booked]="day.value == 'booked'"
                                 [class.absent]="day.value == 'absent'"
                                 [class.present]="attendanceType != 'child' ? day.value == 'present' : day.isBookedDay && day.value == 'present'"
                                 [class.is_booked_day]="attendanceType == 'child' && day.value == 'present' && !day.isBookedDay">
                                {{day.date?.date()}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex="39"
                 fxFlex.sm="100"
                 fxFlex.xs="100"
                 style="padding-right: 10px;">
                <div class="legends"
                     style="margin-top: 10px;">
                    <h3>Legends</h3>
                    <div>
                        <div class="legend"
                             [class.legend-activated]="selectedDay?.value==='present' && selectedDay?.isBookedDay">
                            <span class="legendbox green"></span>
                            <p>{{ 'Present' | translate }}</p>
                        </div>
                        <div class="legend"
                             [class.legend-activated]="selectedDay?.value==='absent'">
                            <span class="legendbox red"></span>
                            <p>{{ 'Absent' | translate }}t&nbsp;</p>
                        </div>
                        <div class="legend"
                             [class.legend-activated]="selectedDay?.value==='bank_holiday'">
                            <span class="legendbox blue"></span>
                            <p>{{ 'Holiday' | translate }}</p>
                        </div>
                    </div>
                </div>
                <div class="legend-devider"></div>
                <div class="boxes"
                     fxLayout="row wrap"
                     fxLayoutAlign="space-between start">
                    <div fxFlex="47"
                         fxFlex.sm="100"
                         fxFlex.xs="100"
                         class="box green"
                         style="margin:0 5px 5px 0;">
                        <p>{{ 'Total' | translate }} <br>{{ 'Attendance Days' | translate }}</p>
                        <span class="count">{{attendanceKpi?.totalAttendance}}</span>
                    </div>
                    <div fxFlex="47"
                         fxFlex.sm="100"
                         fxFlex.xs="100"
                         class="box blue"
                         style="margin:0 0px 5px 5px;">
                        <p>{{ 'Total' | translate }} <br>{{ 'Holidays' | translate }}</p>
                        <span class="count">{{attendanceKpi?.bankHolidays}}</span>
                    </div>
                    <div fxFlex="47"
                         fxFlex.sm="100"
                         fxFlex.xs="100"
                         class="box red"
                         style="margin:5px 5px 0 0;">
                        <p> {{ 'Total' | translate }}<br>{{ 'Absent' | translate }}</p>
                        <span class="count">{{attendanceKpi?.totalAbsent}}</span>
                    </div>
                    <div fxFlex="47"
                         fxFlex.sm="100"
                         fxFlex.xs="100"
                         class="box orange"
                         style="margin:5px 0 0 5px;">
                        <p> {{ 'Attendance' | translate }}<br>{{ 'Percentage' | translate }}</p>
                        <span class="count">{{attendanceKpi?.attendancePercentage | number : '1.0-1'}}%</span>
                    </div>
                </div>
                <div class="selected-date"
                     style="margin-top: 20px;">
                    <h3>{{selectedDay?.date | date : 'd-MMM-y'}}</h3>
                    <div fxLayout="row wrap"
                         fxLayoutAlign="space-between stretch">
                        <div fxFlex="45"
                        style="border-right: 1px solid lightgrey" >
                            <h4>Scheduled Hours ({{selectedDay?.detail?.scheduledHours}})</h4>
                            <div class="in-out">
                                <div fxFlex="49">Shift</div>
                                <div fxFlex="49">
                                    <span class="text-color text-small">
                                        {{selectedDay?.detail?.scheduledTimeIn != 0 ?(selectedDay?.detail?.scheduledTimeIn * 1000 | date :'hh:mm a') : 'N/A'}}
                                        -
                                        {{selectedDay?.detail?.scheduledTimeOut != 0 ?(selectedDay?.detail?.scheduledTimeOut * 1000 | date :'hh:mm a') : 'N/A'}}
                                    </span>
                                </div>
                            </div>
                            <div class="in-out">
                                <div fxFlex="49">{{ 'Break' | translate }}</div>
                                <div fxFlex="49">
                                    <span class="text-color text-small">
                                        {{selectedDay?.detail?.todayBreakTimeAllowedInMinutes != 0 ?selectedDay?.detail?.todayBreakTimeAllowedInMinutes + ' minutes' : 'No Break'}} 
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="49"  >
                            <h4>{{ 'Actual Hours' | translate }} ({{selectedDay?.detail?.attendedHours}})</h4>
                            <div class="in-out"
                                 *ngIf="!selectedDay">
                                <div fxFlex="49">{{ 'Time In' | translate }}</div>
                                <div fxFlex="49"><span class="text-color text-small">N/A</span></div>
                            </div>
                            <div class="in-out"
                                 *ngIf="!selectedDay">
                                <div fxFlex="49">{{ 'Time Out' | translate }}</div>
                                <div fxFlex="49"><span class="text-color text-small">N/A</span></div>
                            </div>
                            <ng-container *ngIf="selectedDay">
                                <div class="in-out"
                                     *ngIf="selectedDay?.detail?.attendance == 'present'">
                                    <div fxFlex="49">{{ 'Time In' | translate }}</div>
                                    <div fxFlex="49"><span class="text-color text-small">{{selectedDay?.detail?.timeIn !=
                                            0 ?
                                            (selectedDay?.detail?.timeIn * 1000 | date : 'hh:mm a') : 'N/A'}}</span>
                                    </div>
                                </div>
                                <div class="in-out"
                                     *ngIf="selectedDay?.detail?.attendance == 'present'">
                                    <div fxFlex="49">Time Out</div>
                                    <div fxFlex="49"><span class="text-color text-small">{{selectedDay?.detail?.timeOut !=
                                            0 ?
                                            (selectedDay?.detail?.timeOut * 1000 | date : 'hh:mm a') : 'N/A'}}</span>
                                    </div>
                                </div>
                                <div class="in-out"
                                     *ngIf="selectedDay?.detail?.attendance != 'present'">
                                    <div fxFlex="49">{{ 'Time In' | translate }}</div>
                                    <div fxFlex="49"><span class="text-color text-small">N/A</span></div>
                                </div>
                                <div class="in-out"
                                     *ngIf="selectedDay?.detail?.attendance != 'present'">
                                    <div fxFlex="49">{{ 'Time Out' | translate }}</div>
                                    <div fxFlex="49"><span class="text-color text-small">N/A</span></div>
                                </div>
                            </ng-container>
                            <div class="in-out">
                                <div fxFlex="49">{{ 'Break' | translate }}</div>
                                <div fxFlex="49">
                                    <span class="text-color text-small">
                                        {{selectedDay?.detail?.todayBreakTimeTakenInMinutes != 0 ?selectedDay?.detail?.todayBreakTimeTakenInMinutes + ' minutes' : 'No Break'}} 
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="legend-devider"></div>
                <div class="selected-date"
                     style="margin-top: 20px;">
                    <h3>{{ 'Comments' | translate }}</h3>
                    {{selectedDay?.detail?.note}}
                </div>
            </div>
        </div>
    </mat-card>
</div>