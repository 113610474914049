<mat-card>
  <div fxLayout="row wrap"
       fxLayoutAlign="start center"
       fxLayoutGap="15px"
       class="top-heading text-bolder text-color">
    {{pageHeading}}
  </div>
</mat-card>
<mat-card style="background-color: #ffffff26 !important; border: 0px !important;">
  <p class="text-normal"
     style="color: #4A4A4A; line-height: 8px; margin-bottom: -5px;">
     {{filterHeading}}
  </p>
  <filter-control [hasButton]="true"
                  [hasClearButton]="true"
                  [layoutAlign]="layoutAlign"
                  (filnalFilters)="filnalFilters($event)"
                  (buttonClickEvent)="checkForInvoiceValidation()"
                  [hasSearch]="true"
                  [hasCustomButton]="true"
                  [customButtonLabel]="customBtnLabel"
                  [searchConfig]="headerProps?.searchConfig"
                  [fxFlexInSearch]="'190px'"
                  [fxFlexInSort]="'170px'"
                  [filterArray]="headerProps?.filterArray"></filter-control>
</mat-card>
<app-table-component (onPagination)="onPagination($event)"
                     (selectedItem)="actionButtonOutput($event)"
                     (emitSelectedCheckBox)="onSelectedCheckBoxEmit($event)"
                     (emitSelectedCheckBoxAll)="onSelectedCheckBoxAllEmit($event)"
                     (emitHeaderCheckBoxValue)="onHeaderCheckBoxValueEmit($event)"
                     [props]="tableConfigAndProps"
                     [hasHeaderCheckBox]="true"></app-table-component>

                     <div class="notFound" *ngIf="dataItems.length == 0">
                      No children have active booking(s) in this period
                     </div>

                       <!-- <app-form-foooter (saveInfoEvent)="onItemSelected()"
                    (clearEvent)="clearForm()"
                    [footerProps]="footerProps"></app-form-foooter> -->

<mat-card style="border-radius: 0px !important;">

  <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px" class="row-margin">
    <div fxFlex="150px">
      <button type="button" mat-button (click)="clearForm()" class="btn-custom-form">
        Clear
      </button>
    </div>
    <div fxFlex="150px">
      <button style="background: #FCB54E;" (click)="checkForInvoiceValidation()" type="button" mat-button class="btn-custom-form">
        Generate Invoice
      </button>
    </div>
  </div>

</mat-card>
