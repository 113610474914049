<form [formGroup]="Form"
      (ngSubmit)="onSubmit()">
  <ng-container>
    <mat-card class="card">
      <div fxLayout="row wrap"
           fxLayoutAlign="space-between center"
           fxLayoutGap="15px"
           class="top-heading text-bolder text-color">
        <div fxFlex>
          {{ 'Room Management' | translate }}
        </div>

        <div fxFlex>
          <div style="display: flex; gap: 15px; place-content: end;font-weight: 600;">
            <div class="inline">
              <button mat-button
                      class="editBtn"
                      (click)="goToEdit()"
                      *ngIf="type == 'view' && editPermit">
                <mat-icon class="editIcon">edit</mat-icon>
                {{ 'Edit' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap"
           fxLayoutAlign="space-between center"
           fxLayoutGap="15px"
           class="top-heading text-bolder">
        <div fxFlex>
          {{ 'Primary Room Allocation' | translate }}
        </div>

      </div>
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px">
        <div fxFlex.xl="28"
             fxFlex.lg="35"
             fxFlex.md="35"
             fxFlex.sm="35"
             fxFlex.xs="100">
          <mat-form-field>
            <mat-label>{{ 'Allocation Type' | translate }}</mat-label>
            <mat-select class="height-40"
                        formControlName="type">
              <mat-option value='automatic'>{{ 'Automatic' | translate }}</mat-option>
              <mat-option value='manual'>{{ 'Manual' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex.xl="28"
             fxFlex.lg="35"
             fxFlex.md="35"
             fxFlex.sm="35"
             fxFlex.xs="100"
             *ngIf="Form.controls['type'].value == 'manual'">
          <!-- <mat-form-field>
            <mat-label>Select Room</mat-label>
            <mat-select class="height-40"
                        formControlName="roomId">
              <mat-option *ngFor="let option of roomsList"
                          [value]="option.value">
                {{option.label}}
              </mat-option>

            </mat-select>
          </mat-form-field> -->

          <mat-form-field>
            <mat-label>{{ 'Select Room' | translate }}</mat-label>
            <input type="text"
                  aria-label="Number"
                  matInput
                  required
                  [formControl]="Form.get('roomLabel')"
                  [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="setValue()">
              <mat-option *ngFor="let option of filteredRooms" [value]="option.label">
                {{option.label}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="getField('roomId')">{{getErrorMessage('roomId')| translate}}</mat-error>
        </mat-form-field>
        </div>
      </div>
      <app-form-foooter *ngIf="type !== 'view'"
                        [footerProps]="footerProps"
                        (backEvent)="goBack()"></app-form-foooter>
      <div fxLayout="row wrap"
           fxLayoutAlign="space-between center"
           fxLayoutGap="15px"
           class="top-heading text-bolder">
        <div fxFlex>
          {{ 'Upcoming Room Moves' | translate }}
        </div>
      </div>
      <div>
        <app-table-component height="auto" [props]="tableConfigAndProps"></app-table-component>
      </div>
    </mat-card>
  </ng-container>
</form>
