<form [formGroup]="Form"
      (ngSubmit)="onSubmit()">
    <ng-container>
        <app-app-headers [headerProps]="genericHeadingProps('Staff Allocation','text-bolder text-color','0px 10px')">
        </app-app-headers>
        <div fxLayout="row wrap"
             fxLayoutAlign="start center"
             fxLayoutGap="15px"
             class="row-margin">
            <div fxFlex.xl="20"
                 fxFlex.lg="25"
                 fxFlex.md="25"
                 fxFlex.sm="45"
                 fxFlex.xs="100"
                 class="text-small text-gray">
                Staff Role
            </div>
            <div fxFlex.xl="20"
                 fxFlex.lg="25"
                 fxFlex.md="25"
                 fxFlex.sm="45"
                 fxFlex.xs="100"
                 class="text-small text-gray">
                Members Name
            </div>
            <div fxFlex.xl="20"
                 fxFlex.lg="25"
                 fxFlex.md="25"
                 fxFlex.sm="45"
                 fxFlex.xs="100"
                 class="text-small text-gray">
                Branch
            </div>
        </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="start center"
             fxLayoutGap="15px"
             class="row-margin">
            <div fxFlex.xl="20"
                 fxFlex.lg="25"
                 fxFlex.md="25"
                 fxFlex.sm="45"
                 fxFlex.xs="100">
                 <mat-form-field>
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="staffRole">
                        <mat-option value="soil">Soil</mat-option>
                        <mat-option value="plastic">Plastic</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex.xl="23"
                 fxFlex.lg="25"
                 fxFlex.md="25"
                 fxFlex.sm="45"
                 fxFlex.xs="100">
                <mat-form-field>
                    <mat-label>Select Name</mat-label>
                    <mat-select formControlName="memberName">
                        <mat-option value="soil">Soil</mat-option>
                        <mat-option value="plastic">Plastic</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex.xl="23"
                 fxFlex.lg="25"
                 fxFlex.md="25"
                 fxFlex.sm="45"
                 fxFlex.xs="100">
                <mat-form-field>
                    <mat-label>Select Branch</mat-label>
                    <input matInput
                           type="text"
                           required>
                    <mat-icon matSuffix
                              (click)="selectBranch($event)"
                              style="background: #FCB54E;margin:0px;
                              width: 25px;border-top-right-radius: 10px;border-bottom-right-radius: 10px;
                              height: 41px;">
                        <img [src]="emailIcon"
                             style="width: 24px;
                             height: 15px;">
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>
        <app-form-foooter [footerProps]="footerProps"></app-form-foooter>
    </ng-container>
</form>