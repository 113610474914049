<aside class="admin-sidebar"
       style="background: #fff 0% 0% no-repeat padding-box;">
       <div fxLayout="row" fxLayoutAlign="space-between center">
         <div fxFlex="49">
          <mat-icon style="color: #FCB54E;
          font-size: 33px;
          margin: 15px 13px;
          cursor: pointer;"
          (click)="onMenu()">menu</mat-icon>
         </div>
         <div fxFlex="49">
          <div >
            <img
            class="sidelogo"
                 src="assets/images/sdn/side-logo.png">
          </div>
         </div>
       </div>
  <!-- <div class="logo">
    <div >
      <img style="width: 65%;
      margin-left: 25px;"
           class="logoImage"
           src="assets/images/sdn/side-logo.png">
    </div>

      <mat-icon style="color: #FCB54E;
      font-size: 33px;
      margin: 15px 10px;
      cursor: pointer;
      position: absolute;top: 10px;left: 8px; bottom: 10px;"
                (click)="onMenu()">menu</mat-icon>
  </div> -->
  <div class="admin-sidebar-wrapper"
       style="height: calc(100vh - 120px);"
       [ngStyle]="sideMenuOpened?'':{'padding-color':'10px 5px'}"
       
       malihu-scrollbar
       [scrollbarOptions]="scrollbarOptions">
    <ul class="menu">

      <li class="menu-item"
          (click)="onMenuClick(sideMenu, $event, i)"
          *ngFor="let sideMenu of menus;let i = index" [ngStyle]="(sideMenu.visibility) ? {'display': 'block'} : {'display': 'none'}"   >
        <mat-accordion displayMode="default"
                       [multi]="false"
                       [hideToggle]="false">
          <mat-expansion-panel (opened)="sideMenu.opened"
                               (closed)="!sideMenu.opened"
                               [expanded] = "sideMenu.opened"
                               hideToggle>
            <mat-expansion-panel-header (mouseover)="onHover('over', sideMenu)"
                                        (mouseout)="onHover('out', sideMenu)"
                                        collapsedHeight="50px"
                                        expandedHeight="50px">
              <button style="border: 0;padding: 0;" *ngIf="sideMenu.isParent"
                 class="parent menu-link"
                 [class.opened]="sideMenu.opened"
                 >
              <div  style="width: 100%;height: 100%;padding: 0 10px;">
                <span class="menu-icon">
                  <img class="filter-color"
                       style="margin-top: 12px;margin-left: 0px;"
                       src="assets/images/menu/menu-svg/{{sideMenu.image}}.svg"
                       width="20px"
                       height="20px">
                </span>
                <span class="menu-label"
                      >
                  <span class="menu-name">{{sideMenu.label | translate}}</span>
                </span>
                <span style="margin-left: 3px;"
                      >
                  <img class="filter-color"
                       style="margin-top: 16px;float: right;"
                       [src]="sideMenu.arrowImg"
                       width="12px"
                       height="12px">
                </span>
              </div>
              </button>

              <button  style="border: 0;padding: 0;" *ngIf="!sideMenu.isParent"
                 class="parent menu-link"
                 [class.opened]="sideMenu.opened"
                 [ngClass]="{'active': sideMenu.routerLink == isCurrentRouteActive}"
                 >
               <div style="width: 100%;height: 100%;padding: 0 10px;">
                <span class="menu-icon">
                  <!-- <mat-icon style="margin-top: 5px;">{{sideMenu.image}}</mat-icon> *ngIf="!sideMenu.active" -->
                  <img class="filter-color"
                       style="margin-top: 12px;margin-left: 0px;"
                       src="assets/images/menu/menu-svg/{{sideMenu.image}}.svg"
                       width="20px"
                       height="20px">
                </span>
                <span class="menu-label"
                      >
                  <span class="menu-name">{{sideMenu.label | translate}}</span>
                </span>
               </div>
              </button>
            </mat-expansion-panel-header>
            <ul class="sub-menu">
              <li *ngFor="let childMenu of sideMenu.children; let last = last"
                  (click)="onChildClick(childMenu, $event, sideMenu)"
                  class="menu-item sub-menu-item" [ngStyle]="(childMenu.visibility) ? {'display': 'block'} : {'display': 'none'}" >
                <button style="border: 0;padding: 0;" class="menu-link"
                   
                   [ngClass]="{'last-child': last,'active': childMenu.routerLink == isCurrentRouteActive}"
                   >
                  <div style="width: 100%;height: 100%;padding: 0 10px;">
                    <span class="menu-icon">
                      <!-- <mat-icon style="margin-top: 5px;">{{sideMenu.image}}</mat-icon> -->
                      <img class="filter-color"
                           style="margin-top: 10px;margin-left: 0px;"
                           src="assets/images/menu/menu-svg/star_left.svg"
                           width="9px"
                           height="16px">
                      <img class="filter-color"
                           style="margin-top: 10px;margin-left: 2px;"
                           src="assets/images/menu/menu-svg/star_right.svg"
                           width="9px"
                           height="16px">
                    </span>
                    <!-- <span class="menu-label"
                          *ngIf="!isSmall"> -->
                    <span class="menu-label">
                      <span class="menu-name">{{childMenu.label | translate}}</span>
                    </span>
                  </div>
                </button>
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
    </ul>
  </div>
</aside>