<mat-card class="row-margin-tb card-dark">
    <div fxLayout="row wrap"
         fxLayoutAlign="start start"
         fxLayoutGap="10px">
        <div fxFlex="25"
             fxFlex.md="35"
             fxFlex.sm="40"
             fxFlex.xs="100">
            <mat-card style="height: 100vh;"
                      class="card">
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     class="col-flex-margins for-select-row"
                     (click)="onFormClick(1)"
                     [ngClass]="{'form-menu-clicked': formNo === 1}">
                    <span class="form-select-icon"> 1</span>
                    <span class="form-select-label">Session Information</span>
                </div>
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     class="col-flex-margins for-select-row"
                     (click)="onFormClick(2)"
                     [ngClass]="{'form-menu-clicked': formNo === 2}">
                    <span class="form-select-icon"> 2</span>
                    <span class="form-select-label">Session Pricing</span>
                </div>
            </mat-card>

        </div>
        <div fxFlex="73" fxFlex.md="60" fxFlex.sm="55" fxFlex.xs="100"  >
            <app-information-form *ngIf="formNo===1"
                                  [parentId]="parentId"
                                  (emitFormData)="onEmitFormData($event)"
                                  (back)="onBack()"></app-information-form>
            <app-pricing-form *ngIf="formNo===2"
                              [parentId]="parentId"
                              [childId]="pricingId"
                              [editPermit]="editPermit"
                              (emitFormData)="onEmitFormData($event)"
                              (back)="onBack()"></app-pricing-form>
        </div>
    </div>

</mat-card>
