<form [formGroup]="Form">

    <div class="top-heading text-bolder text-color">
        {{ type == 'add' ? 'Add' : 'Update' }} {{ 'Additional Item' | translate }}
    </div>

    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="15px"
         class="row-card-margins">
    <div fxFlex.xl="47"
        fxFlex.lg="47"
        fxFlex.md="47"
        fxFlex.sm="47"
        fxFlex.xs="100"
        class="text-small text-gray">
        {{ 'Select Item' | translate }}

        <!-- <mat-form-field style="margin-top: 10px;">
        <mat-select formControlName="additionalItemId" placeholder="Additional Items" (selectionChange)="checkForAmount()">
            <mat-option *ngFor="let item of additionalItems" [value]="item.id">{{ item.name }}</mat-option>
        </mat-select>
        </mat-form-field> -->

        <mat-form-field style="margin-top: 10px;">
            <!-- <mat-label>Select Room</mat-label> -->
            <input type="text"
                  aria-label="Number"
                  matInput
                  required
                  [formControl]="Form.get('additionalLabel')"
                  [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="setValue()">
              <mat-option *ngFor="let option of filteredAdditionalItems" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
            <!-- <mat-error *ngIf="getField('roomId')">{{getErrorMessage('roomId')| translate}}</mat-error> -->
        </mat-form-field>
    </div>
    <div fxFlex.xl="47"
        fxFlex.lg="47"
        fxFlex.md="47"
        fxFlex.sm="47"
        fxFlex.xs="100"
        class="text-small text-gray">
        {{ 'Select Date' | translate }}
        <mat-form-field (click)="desired3.open()"
                        style="margin-top: 10px;">
        <input matInput
                readonly
                (dateChange)="dateChangeStatic(Form,'date',$event)"
                [matDatepicker]="desired3"
                formControlName="date"
                [min]="minDate"
                [max]="maxDate"
                >
        <mat-icon matSuffix>
            <img [src]="calendar"
                style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
        </mat-icon>
        <mat-datepicker #desired3></mat-datepicker>
        </mat-form-field>
    </div>
    <div fxFlex.xl="47"
        fxFlex.lg="47"
        fxFlex.md="47"
        fxFlex.sm="47"
        fxFlex.xs="100"
        class="text-small text-gray">
        {{ 'Amount' | translate }}
        <mat-form-field style="margin-top: 10px;">
        <input matInput
                type="number"
                min="1"
                class="ml-15"
                maxlength="5"
                (blur)="onBlurEvent($event)"
                formControlName="rate"
                />
        <!-- <mat-error *ngIf="getField('firstName')">{{getErrorMessage('firstName')| translate}}</mat-error> -->
        <span class="pound-left" matSuffix>£&nbsp;</span>
        </mat-form-field>
    </div>
    <div fxFlex.xl="47"
        fxFlex.lg="47"
        fxFlex.md="47"
        fxFlex.sm="47"
        fxFlex.xs="100"
        class="text-small text-gray">
        {{ 'Quantity' | translate }}
    <mat-form-field style="margin-top: 10px;">
        <input matInput
                type="number"
                min="1"
                maxlength="4"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null; if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                formControlName="quantity"
                />
        <!-- <mat-error *ngIf="getField('firstName')">{{getErrorMessage('firstName')| translate}}</mat-error> -->
    </mat-form-field>
    </div>
    <div fxFlex.xl="47"
         fxFlex.lg="47"
         fxFlex.md="47"
         fxFlex.sm="47"
         fxFlex.xs="100"
         class="text-small text-gray">
          {{ 'Select Child' | translate }}

        <mat-form-field style="margin-top: 10px;">
            <!-- <mat-label>Select Room</mat-label> -->
            <input type="text"
                aria-label="Number"
                matInput
                required
                [formControl]="Form.get('childLabel')"
                [matAutocomplete]="auto2">
            <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="setChildValue()">
            <mat-option *ngFor="let option of filteredChildren" [value]="option.name">
                {{option.name}}
            </mat-option>
            </mat-autocomplete>
            <!-- <mat-error *ngIf="getField('roomId')">{{getErrorMessage('roomId')| translate}}</mat-error> -->
        </mat-form-field>
    </div>
    </div>

    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="15px"
         class="ml-10">

         <div fxFlex.xl="95"
            fxFlex.lg="95"
            fxFlex.md="95"
            fxFlex.sm="95"
            fxFlex.xs="100"
            class="text-small text-gray">
            {{ 'Note' | translate }}
            <mat-form-field style="margin-top: 10px;">
                <textarea matInput
                          [rows]="4"
                          style="resize: none;"
                          placeholder="{{ 'Add note here' | translate }}"
                          formControlName="comment"
                          maxlength="200"
                          >
                </textarea>
            </mat-form-field>
        </div>


    </div>

    <div *ngIf="mode == 'edit'" class="ml-10" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">

        <div fxFlex="20">
            <button mat-button
                    style="width: 90%;background-color: rgb(193, 187, 185);"
                    type="button"
                    class="btn-custom-form"
                    (click)="clearAdditionalItem()">{{ 'Cancel' | translate }}</button>
        </div>
        <div fxFlex="20">
            <button mat-button
                    style="width: 90%;"
                    class="btn-custom-form"
                    type="button"
                    (click)="onAddItem()"
                    [disabled]="isAdditonalFieldsInvalid()"> {{ 'Save' | translate }} </button>
        </div>
   </div>
</form>
